import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Link from 'next/link';
import { HTMLAttributeAnchorTarget } from 'react';

/**
 * ITeamCard
 *
 * @interface ITeamCard
 */
export interface ITeamCard {
  /**
   * Name of the Team Card
   *
   * @memberof ITeamCard
   * @member {string} name
   */
  name: string;
  /**
   * Title of the Team Card
   *
   * @memberof ITeamCard
   * @member {string} title
   */
  title: string;
  /**
   * The Image of the Team Card
   *
   * @memberof ITeamCard
   * @member {string} image
   */
  image: string;
  /**
   * The alt of the Team Card
   *
   * @memberof ITeamCard
   * @member {string} [alt]
   */
  alt?: string;
  /**
   * The label of the Team Card
   *
   * @memberof ITeamCard
   * @member {string} [label]
   */
  label?: string;
  /**
   * Url of the Team Card
   *
   * @memberof ITeamCard
   * @default '#'
   * @member {string} [url]
   */
  url?: string;
  /**
   * The optional target of the Card
   *
   * @memberof ITeamCard
   * @default '_self'
   * @member {HTMLAttributeAnchorTarget} [target]
   */
  target?: HTMLAttributeAnchorTarget;
}

/**
 * TeamCard
 *
 * @param {ITeamCard} props - The props for the TeamCard component
 * @returns {React.FC<ITeamCard>} Component
 */
const TeamCard: React.FC<ITeamCard> = ({
  name,
  title,
  label,
  alt,
  image,
  url = '#',
  target = '_self',
}: ITeamCard) => {
  return (
    <div data-testid="team-card" className="w-full">
      <Link href={url} aria-label={label} className="group" target={target}>
        {image && (
          <div className="aspect-square relative rounded-[20px] overflow-hidden mb-6">
            <ContentfulImage
              data-testid="feature-card-image"
              src={image}
              layout="fill"
              alt={alt || title}
              objectFit="cover"
              className="scale-100 aspect-square group-hover:scale-[102%] transition-transform duration-100"
            />
          </div>
        )}
        <div className="w-full text-center">
          <Heading size="h5" font="petco" className="text-mkNavy-400 mb-2">
            {name}
          </Heading>

          <Paragraph size="body4" font="amasis">
            {title}
          </Paragraph>
        </div>
      </Link>
    </div>
  );
};

export default TeamCard;
