import { ImageLoaderProps } from "next/legacy/image";

/**
 * Loads images from the CDN
 *
 * @param {ImageLoaderProps} params- Image loader props
 * @returns {string} - Image URL
 */
function contentFulImageLoader({
  src,
  width,
  quality,
}: ImageLoaderProps): string {
  if (quality) {
    return `${src}?q=${quality}&w=${width}&fm=webp`;
  } else {
    return `${src}?w=${width}&fm=webp`;
  }
}

export default contentFulImageLoader;
