import { defaultEventEmitter } from '@/lib/analytics/commonEmitter';
import { notApplicableString } from '@/lib/constants/constants/analytics';
import { PetStatus } from '@/lib/constants/types/analytics';
import { InferredSpecies } from '@/lib/constants/types/pets';

/**
 * Start Add Pet Event Handler Data
 *
 * @interface IStartAddPetEventData
 */
export interface IStartAddPetEventData {
  /** Click Text */
  clickText: string;
  /** Where the add pet was initiated */
  context: 'Dashboard' | 'Sign Up Flow';
  /** Pet Status */
  petStatus?: PetStatus;
  /** What type of user is creating the pet */
  listingSource: 'Consumer Pet Listing' | 'Shelter Pet Listing';
  /** Pet Species */
  species?: InferredSpecies;
}

/**
 * Send the start add pet event to google analytics
 *
 * @param {IStartAddPetEventData} data - The data to send
 */
export const startAddPetEventHandler = ({
  clickText,
  context,
  petStatus,
  listingSource,
  species,
}: IStartAddPetEventData) => {
  const eventData = {
    name: 'start_add_pet',
    data: {
      click_text: clickText,
      context,
      items: [
        {
          pet_id: notApplicableString,
          pet_name: notApplicableString,
          pet_status: petStatus || notApplicableString,
          listing_source: listingSource,
          species: species || notApplicableString,
          location_id: notApplicableString,
        },
      ],
    },
  };
  defaultEventEmitter.handleEvent(eventData);
};
