/* eslint-disable jsdoc/tag-lines */
/**
 * Helper function used to append a key:value pair to an object definition if
 * the value is not undefined.
 *
 * @example
 *   const isUndefined = undefined;
 *
 *   const objectWithNoUndefinedValues = {
 *     key1: 'value1',
 *     ...appendAttributeIfExists('newKey', 'not sure if is not undefined'),
 *     ...appendAttributeIfExists('newKeyUndefined', isUndefined),
 *   };
 *
 *   // result { key1:'value1', newKey: 'not sure if is not undefined' }
 *
 * @param {string} key - The key of the key:value pair
 * @param {unknown} value - The value of the key:value pair
 * @returns {object} - The object used to append to the object definition
 */
const appendAttributeIfExists = (
  key: string,
  value: unknown
): { [key: string]: unknown } => (value !== undefined ? { [key]: value } : {});

export default appendAttributeIfExists;
