import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { accentProps } from '@/lib/constants/constants/accents';
import { useAccent } from '@/lib/hooks/accentContext/AccentContext';
import clsx from 'clsx';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';

/**
 * IFlexibleCard Interface for the Flexible Card Component
 *
 * @interface IFlexibleCard
 */
export interface IFlexibleCard {
  /**
   * The Category of the Flexible Card
   *
   * @memberof IFlexibleCard
   * @member {string} [category]
   */
  category?: string;
  /**
   * Title of the Flexible Card
   *
   * @memberof IFlexibleCard
   * @member {string} title
   */
  title: string;
  /**
   * The Copy of the Flexible Card
   *
   * @memberof IFlexibleCard
   * @member {React.ReactNode | string} [copy]
   */
  copy?: React.ReactNode | string;
  /**
   * The label of the Flexible Card
   *
   * @memberof IFlexibleCard
   * @member {string} [label]
   */
  label?: string;
  /**
   * The optional classes for the component
   *
   * @memberof IFlexibleCard
   * @default ''
   * @member {string} [className]
   */
  className?: string;
  /**
   * Url of the Flexible Card
   *
   * @memberof IFlexibleCard
   * @member {string} url
   */
  url: string;
  /**
   * The optional variant for the component.
   *
   * The normal style for the component will display a headline color:
   * text-mkNavy-400, and a box shadow that has better visibility with Gray
   * backgrounds The variant style for the component will display a headline
   * color: text-base-400, and a box shadow that has better visibility with
   * light backgrounds
   *
   * @memberof IFlexibleCard
   * @default false
   * @member {boolean} [useVariant]
   */
  useVariant?: boolean;
}

/**
 * Flexible Card
 *
 * @param {IFlexibleCard} props - The props for the Flexible Card Component
 * @returns {React.FC<IFlexibleCard>} Flexible Card Component
 */
const FlexibleCard: React.FC<IFlexibleCard> = ({
  category,
  title,
  copy,
  label,
  className = '',
  url,
  useVariant = false,
}: IFlexibleCard) => {
  const { accent } = useAccent();

  return (
    <div
      data-testid="flexible-card"
      className={twMerge(clsx('mb-6 sm:mb-0 h-full w-full', className))}
    >
      <div
        className={clsx(
          'bg-neutral-100 flex flex-col items-start justify-between rounded-3xl p-6 w-full h-full min-h-[180px]',
          useVariant ? 'shadow-cardVariant' : 'shadow-card'
        )}
      >
        <div className="w-full mb-3 flex flex-col items-start">
          <Paragraph size="overline" className="mb-1 text-neutral-700">
            {category}
          </Paragraph>

          <Heading
            size="h5"
            font="petco"
            className={clsx(
              'mb-4 md:mb-3',
              useVariant ? 'text-base-400' : 'text-mkNavy-400'
            )}
          >
            {title}
          </Heading>

          <div className="text-left [&>*]:text-base [&>*]:text-neutral-800 rich-text-with-links">
            {copy}
          </div>
        </div>
        {url && (
          <Link href={url} target="_blank" className="group">
            <div
              className={clsx(
                'font-medium underline underline-offset-[10px] decoration-[3px] pb-2',
                {
                  'decoration-base-300': accent === accentProps.ORG,
                  'decoration-mkPink-400 group-hover:decoration-mkNavy-400':
                    accent === accentProps.ADOPT,
                  'decoration-mkLightBlue-400 group-hover:decoration-mkNavy-400':
                    accent === accentProps.CARE,
                }
              )}
            >
              <p
                className={clsx('transition-colors font-bold text-mkNavy-400', {
                  'group-hover:text-base-300': accent === accentProps.ORG,
                  'group-hover:text-mkNavy-400':
                    accent === accentProps.ADOPT || accent === accentProps.CARE,
                })}
              >
                {label}
              </p>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};

export default FlexibleCard;
