import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import ButtonLink from '@/components/atoms/ButtonLink/ButtonLink';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { accentProps } from '@/lib/constants/constants/accents';
import { useAccent } from '@/lib/hooks/accentContext/AccentContext';
import clsx from 'clsx';
import { HTMLAttributeAnchorTarget } from 'react';

/**
 * ISplitContent
 *
 * @interface ISplitContent
 */
export interface ISplitContent {
  /**
   * The overline text to display
   *
   * @memberof ISplitContent
   * @member {string} overline
   */
  overline: string;
  /**
   * The headline text to display
   *
   * @memberof ISplitContent
   * @member {React.ReactNode} headline
   */
  headline: React.ReactNode;
  /**
   * The copy to display
   *
   * @memberof ISplitContent
   * @member {string | React.ReactNode} copy
   */
  copy: string | React.ReactNode;
  /**
   * The image src
   *
   * @memberof ISplitContent
   * @member {string} imageSrc
   */
  imageSrc: string;
  /**
   * The image alt
   *
   * @memberof ISplitContent
   * @member {string} [imageAlt]
   */
  imageAlt?: string;
  /**
   * The link url
   *
   * @memberof ISplitContent
   * @member {'Left' | 'Right'} orientation
   */
  orientation: 'Left' | 'Right';
  /**
   * The button
   *
   * @memberof ISplitContent
   * @member {object} [button]
   */
  button?: {
    /**
     * The label of the button
     *
     * @memberof ISplitContent.button
     * @member {string} label
     */
    label: string;
    /**
     * The url of the button
     *
     * @memberof ISplitContent.button
     * @member {string} url
     */
    url: string;
    /**
     * The target window or frame that the linked document will open in.
     *
     * @memberof ISplitContent.button
     * @member {'_blank' | '_self' | '_parent' | '_top'} [target]
     */
    target?: HTMLAttributeAnchorTarget;
    /**
     * The alternate text to display when the image cannot be displayed.
     *
     * @memberof ISplitContent.button
     * @member {string} [altTitle]
     */
    altTitle?: string;
  };
  /**
   * The background color
   *
   * @memberof ISplitContent
   * @member {string} [background]
   */
  background?: string;
}

/**
 * SplitContent Content with media side by side
 *
 * @param {ISplitContent} props - The props for the SplitContent component
 * @returns {React.FC<ISplitContent>} Component
 */
const SplitContent: React.FC<ISplitContent> = ({
  overline,
  headline,
  copy,
  imageSrc,
  imageAlt,
  orientation,
  button,
  background,
}: ISplitContent) => {
  const { accent } = useAccent();

  return (
    <div
      data-testid="split-content"
      style={{
        background: `linear-gradient(${
          orientation === 'Left' ? 'to right' : 'to left'
        },  ${background} 0%, ${background} 50%, transparent 50%, transparent 100%)`,
      }}
    >
      <div
        className={clsx(
          'flex flex-wrap items-stretch md:mx-auto md:px-0',
          orientation === 'Left' && 'flex-row-reverse'
        )}
      >
        <div className="w-full md:w-2/4 lg:relative">
          <div
            className={clsx(
              'half-screen-width',
              orientation === 'Right' && 'no-reverse'
            )}
          >
            <ContentfulImage
              src={imageSrc}
              alt={imageAlt}
              layout="responsive"
              width={700}
              height={700}
              objectFit="cover"
            />
          </div>
        </div>
        <div
          className="w-full md:w-2/4 px-6 sm:px-8 pt-6 sm:pt-12 pb-16 sm:pb-20 lg:px-12 flex items-center xl:px-24"
          style={{ backgroundColor: background }}
        >
          <AnimateItem from={{ translateY: 20 }}>
            <Paragraph
              size="body3"
              className="mb-1 sm:mb-5 lg:mb-3 text-neutral-700"
            >
              {overline}
            </Paragraph>
            <Heading size="h2" className="mb-4 lg:mb-6 [&>*]:text-mkNavy-400">
              {headline}
            </Heading>
            <div
              className={clsx(
                accent === accentProps.ORG ? 'font-petco' : 'font-amasis',
                'rich-text-with-links'
              )}
            >
              {copy}
            </div>
            {button && (
              <ButtonLink
                variant="light"
                href={button.url}
                alt={button.altTitle}
                target={button.target}
              >
                {button.label}
              </ButtonLink>
            )}
          </AnimateItem>
        </div>
      </div>
    </div>
  );
};

export default SplitContent;
