import Button, { IButton } from '@/components/atoms/Button/Button';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

/**
 * IHeroIllustration
 *
 * @interface IHeroIllustration
 */
export interface IHeroIllustration {
  /**
   * The overline text to display.
   *
   * @memberof IHeroIllustration
   * @member {string} [overline]
   */
  overline?: string;
  /**
   * The title slides to display.
   *
   * @memberof IHeroIllustration
   * @member {string | React.ReactNode[]} titleSlides
   */
  titleSlides: string | React.ReactNode[];
  /**
   * The content text to display.
   *
   * @memberof IHeroIllustration
   * @member {string | React.ReactNode} [body]
   */
  body?: React.ReactNode | string;
  /**
   * The button to display.
   *
   * @memberof IHeroIllustration
   * @member {IButton} [button]
   */
  button?: IButton;
  /**
   * The image
   *
   * @memberof IHeroIllustration
   * @member {object} image
   */
  image: {
    /**
     * The image url
     *
     * @memberof IHeroImage
     * @member {string} url
     */
    url: string;
    /**
     * The image alt text
     *
     * @memberof IHeroImage
     * @member {string} [alt]
     */
    alt?: string;
    /**
     * The image width
     *
     * @memberof IHeroImage
     * @member {number} width
     */
    width: number;
    /**
     * The image height
     *
     * @memberof IHeroImage
     * @member {number} height
     */
    height: number;
  };
  /**
   * The optional classes
   *
   * @memberof IHeroIllustration
   * @default ''
   * @member {string} [className]
   */
  className?: string;
}

/**
 * HeroIllustration A hero with an illustration
 *
 * @param {IHeroIllustration} props - The props for the HeroIllustration
 *   component
 * @returns {React.FC<IHeroIllustration>} Component
 */
const HeroIllustration: React.FC<IHeroIllustration> = ({
  overline,
  titleSlides,
  body,
  button,
  image,
  className = '',
}: IHeroIllustration) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (titleSlides.length > 1) {
      const interval = setInterval(() => {
        setCurrentIndex((prev) =>
          prev === titleSlides.length - 1 ? 0 : prev + 1
        );
      }, 1500);

      return () => {
        clearInterval(interval);
      };
    }
  }, [titleSlides]);

  return (
    <div
      data-testid="hero-illustration"
      className={`relative bg-base-100 h-[calc(100vh-60px)] md:h-auto md:min-h-[694px] flex items-center justify-center overflow-hidden md:py-8 ${className}`}
    >
      <div className="text-center relative z-10 translate-y-[-110px] sm:translate-y-[-100px] px-6 sm:px-0 md:-translate-y-10 sm:grid sm:grid-cols-6 sm:gap-6 md:grid-cols-12 md:gap-8">
        <div className="sm:col-start-2 sm:col-span-4 md:col-start-4 md:col-span-6">
          {overline && (
            <Paragraph size="body5" styling="regular" className="mb-3">
              {overline}
            </Paragraph>
          )}

          {titleSlides.length === 1 ? (
            <Heading size="h1" font="petco" className="text-base-400 mb-4">
              {titleSlides[0]}
            </Heading>
          ) : (
            <Heading
              size="h1"
              font="petco"
              className={clsx(
                'mb-4',
                currentIndex === 0 && 'text-base-400',
                currentIndex === 1 && 'text-base-300',
                currentIndex === 2 && 'text-base-500'
              )}
            >
              {titleSlides[currentIndex]}
            </Heading>
          )}

          {body && <div className="mb-8">{body}</div>}

          {button && <Button {...button} data-testid="cta-button" />}
        </div>
      </div>
      {image.url && (
        <div className="absolute right-0 -bottom-2 w-3/5 text-right md:w-full">
          <ContentfulImage
            src={image.url}
            alt={image.alt}
            width={image.width}
            height={image.height}
          />
        </div>
      )}
    </div>
  );
};

export default HeroIllustration;
