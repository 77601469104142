import SplitContent from '@/components/organisms/SplitContent/SplitContent';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import {
  RenderNode,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContainerProps } from '..';
import { renderBreakLine } from '@/lib/utils/stringReplace/renderBreakLine';

const renderOptions = {
  renderNode: {
    /**
     * Embedded Asset
     *
     * @param {Document} node - The node
     * @param {string} children - The children
     * @returns {React.ReactElement} - Image Component
     */
    [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
      <p className="text-neutral-800 mb-6 lg:mb-10 md:line-clamp-6 lg:line-clamp-[20] text-body3">
        {children}
      </p>
    ),
  } as unknown as RenderNode,
  renderText: renderBreakLine,
};

/**
 * @param {ContainerProps['splitContent']} props - SplitContentContainerProps
 * @returns {React.ReactNode} - SplitContentContainer
 */
export const SplitContentContainer = (
  props: ContainerProps['splitContent']
) => {
  const button = props.fields.actionLink
    ? {
        label: props.fields.actionLink.fields.label,
        url: props.fields.actionLink.fields.url,
        target: props.fields.actionLink.fields.target,
        altTitle: props.fields.actionLink.fields.altTitle,
      }
    : undefined;

  return (
    <SplitContent
      overline={props.fields.overline}
      headline={documentToReactComponents(props.fields.headline, {
        renderMark: {
          /**
           * Renders the given text as bold and applies the CSS class "font-bold
           * text-mkNavy-400" to it.
           *
           * @param {string} text - The text to be rendered as bold.
           * @returns {React.ReactElement} - The JSX element representing the
           *   bold text with the applied CSS class.
           */
          bold: (text) => (
            <span className="font-bold text-mkNavy-400">{text}</span>
          ),
          /**
           * Renders the given text as italic and applies the CSS classes
           * "font-light", "font-amasis", and "italic" with the color class
           * "text-mkNavy-400" to it.
           *
           * @param {string} text - The text to be rendered as italic.
           * @returns {React.ReactElement} - The JSX element representing the
           *   italic text with the applied CSS classes.
           */
          italic: (text) => (
            <span className="font-light font-amasis italic text-mkNavy-400">
              {text}
            </span>
          ),
        },
      })}
      copy={
        props.fields.copyRichText
          ? documentToReactComponents(props.fields.copyRichText, renderOptions)
          : props.fields.copy
      }
      imageSrc={addHttps(props.fields.media?.fields.file?.url)}
      imageAlt={props.fields.media?.fields.title}
      orientation={props.fields.orientation}
      button={button}
      background={props.fields.background}
    />
  );
};
