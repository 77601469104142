
/**
 * Inserts an Html break line when a new line is present at the text parameter
 *
 * @param {string} text - String to parse
 * @returns {string} The generated output with html break lines
 */
export const renderBreakLine = (text: string) => {
  return text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]);
};
