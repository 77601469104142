import BlobFour from './BlobFour';
import BlobOne from './BlobOne';
import BlobThree from './BlobThree';
import BlobTwo from './BlobTwo';

/**
 * The type of the Blob
 *
 * @interface IBlobListItem
 */
export interface IBlobListItem {
  /**
   * The size of the blob
   *
   * @memberof IBlobListItem
   * @member {number} size
   */
  size: number;
  /**
   * The color of the blob
   *
   * @memberof IBlobListItem
   * @member {string} color
   */
  color: string;
  /**
   * The id of the blob
   *
   * @memberof IBlobListItem
   * @member {string} [id]
   */
  id?: string;
}

const BlobList = {
  blobOne: BlobOne,
  blobTwo: BlobTwo,
  blobThree: BlobThree,
  blobFour: BlobFour,
};

export default BlobList;
