import { IButton } from '@/components/atoms/Button/Button';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import HeroIllustration from '@/components/organisms/HeroIllustration/HeroIllustration';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import {
  RenderNode,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';

/**
 * @param {object} props - HeroIllustrationContainerProps
 * @returns {React.ReactNode} - HeroIllustrationContainer
 */
export const HeroIllustrationContainer = (
  props: ContainerProps['heroIllustration'] & {
    /** Classname */
    className?: string;
  }
) => {
  const button = props.fields.actionLink
    ? ({
        children: props.fields.actionLink?.fields?.label,
        color: 'primary',
        size: 'md',
        type: 'button',
        href: props.fields.actionLink?.fields?.url,
        newTab:
          props.fields.actionLink?.fields?.target === '_blank' ? true : false,
      } as IButton)
    : undefined;

  /**
   * Render Headline
   *
   * @param {Document} headline - The headline
   * @returns {React.ReactElement} - Headline
   */
  function renderHeadline(headline: Document) {
    return documentToReactComponents(headline, {
      renderMark: {
        // eslint-disable-next-line jsdoc/require-jsdoc
        bold: (text) => <strong className="font-petco">{text}</strong>,
      },
      renderNode: {
        /**
         * Embedded Asset
         *
         * @param {Document} node - The node
         * @param {string} children - The children
         * @returns {React.ReactElement} - Image Component
         */
        [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
          <span className="text-inherit">{children}</span>
        ),
      } as unknown as RenderNode,
    });
  }

  const titleSlides = [];
  if (props.fields.headline) {
    titleSlides.push(renderHeadline(props.fields.headline));
  }
  if (props.fields.headlineSlide1) {
    titleSlides.push(renderHeadline(props.fields.headlineSlide1));
  }
  if (props.fields.headlineSlide2) {
    titleSlides.push(renderHeadline(props.fields.headlineSlide2));
  }

  return (
    <HeroIllustration
      overline={props.fields.overline}
      titleSlides={titleSlides}
      body={documentToReactComponents(props.fields.copyRichText, {
        renderMark: {
          // eslint-disable-next-line jsdoc/require-jsdoc
          bold: (text) => <strong className="font-petco">{text}</strong>,
        },
        renderNode: {
          /**
           * Embedded Asset
           *
           * @param {Document} node - The node
           * @param {string} children - The children
           * @returns {React.ReactElement} - Image Component
           */
          [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
            <Paragraph size="body3" font="petco">
              {children}
            </Paragraph>
          ),
        } as unknown as RenderNode,
      })}
      image={{
        url: addHttps(props.fields.image?.fields.file?.url),
        alt: props.fields.image?.fields.description,
        width: props.fields.image?.fields.file?.details.image?.width || 560,
        height: props.fields.image?.fields.file?.details.image?.height || 430,
      }}
      button={button}
    />
  );
};
