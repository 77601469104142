import {
  ColorShade,
  ColorType,
  Colors,
} from '@/components/atoms/Colors/Colors';
import BlobList from './Blobs/BlobList';

/** The type of the Blob */
export type BlobType = keyof typeof BlobList;

/**
 * IBlob
 *
 * @interface IBlob
 */
export interface IBlob {
  /**
   * The type of the Blob
   *
   * @memberof IBlob
   * @member {BlobType} blob
   */
  blob: BlobType;
  /**
   * The size of the blob
   *
   * @memberof IBlob
   * @member {number} size
   */
  size: number;
  /**
   * The color of the blob
   *
   * @memberof IBlob
   * @member {ColorType} colorType
   */
  colorType: ColorType;
  /**
   * The shade of the blob
   *
   * @memberof IBlob
   * @member {ColorShade} colorShade
   */
  colorShade: ColorShade;
  /**
   * The id of the blob
   *
   * @memberof IBlob
   * @member {string} [id]
   */
  id?: string;
  /**
   * The optional classes of the blob
   *
   * @memberof IBlob
   * @member {string} [className]
   */
  className?: string;
}

/**
 * Returns the selected blob based on the provided blob type.
 *
 * @param {BlobType} blob - The type of the blob.
 * @returns {React.FC<IBlob>} The selected blob component.
 */
export const chooseBlob = (blob: BlobType) => {
  const SelectedBlob = BlobList[blob];
  return SelectedBlob;
};

/**
 * Blob A blob image
 *
 * @param {IBlob} props - The props for the Blob component
 * @returns {React.FC<IBlob>} Component
 */
const Blob: React.FC<IBlob> = ({
  blob,
  size,
  colorType,
  colorShade,
  id,
  className = '',
}: IBlob) => {
  const color = Colors[colorType][colorShade];
  const SelectedBlob = chooseBlob(blob);
  return (
    <div data-testid={`blob-${blob}`} className={className}>
      <SelectedBlob size={size} color={color as string} id={id}></SelectedBlob>
    </div>
  );
};

export default Blob;
