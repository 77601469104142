import clsx from 'clsx';

/**
 * @param {object} props - Separator props
 * @param {string} props.color - Color
 * @param {string} props.size - Size
 * @returns {React.ReactNode} - Separator
 */
export const SeparatorContainer = (props: {
  /** Color */
  color?: string;
  /** Size */
  size?: 'default' | 'small';
}) => {
  const colorMap: Record<string, string> = {
    white: 'bg-neutral-100',
    'base 100': 'bg-base-100',
    'base 200': 'bg-base-200',
    'base 300': 'bg-base-300',
    'base 400': 'bg-base-400',
  };

  const color = props.color ? colorMap[props.color] : colorMap['white'];

  return (
    <div
      data-testid="separator-container"
      className={clsx(
        props.size === 'small' ? 'h-8 sm:h-9 md:h-12' : 'h-16 sm:h-20 md:h-28',
        color
      )}
    />
  );
};
