import Image from "next/legacy/image";

/**
 * Renders an anchor element with a target attribute based on the provided URI.
 *
 * @param {string} uri - The URI to link to.
 * @param {React.ReactNode} children - The content to display within the anchor
 *   element.
 * @returns {React.Element} The rendered anchor element.
 */
export function anchorTarget(uri: string, children: React.ReactNode) {
  const isPetcoLink =
    uri.includes('https://petcolove.org/') || uri.startsWith('/');

  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={uri}
      target={isPetcoLink ? '_self' : '_blank'}
      rel={isPetcoLink ? '' : 'noopener noreferrer'}
      className="text-neutral-800 hover:text-base-300 underline focus-visible:ring-1 focus-visible:ring-focus-400"
    >
      {children}
      {!isPetcoLink && (
        <span className="ml-0.5 mr-1 relative -top-0.5">
          <Image
            src="/images/external-link.png"
            alt="↗"
            width={12}
            height={12}
          />
        </span>
      )}
    </a>
  );
}
