import Button from '@/components/atoms/Button/Button';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * Represents a Hero button component.
 *
 * @interface IHeroButton
 */
export interface IHeroButton {
  /**
   * The label to display on the button.
   *
   * @memberof IHeroButton
   * @member {string} label
   */
  label: string;
  /**
   * The URL that the button should navigate to.
   *
   * @memberof IHeroButton
   * @member {string} url
   */
  url: string;
  /**
   * The target window or frame that the linked document will open in.
   *
   * @memberof IHeroButton
   * @member {'_blank' | '_self' | '_parent' | '_top'} target
   */
  target: '_blank' | '_self' | '_parent' | '_top';
  /**
   * The alternate text to display when the image cannot be displayed.
   *
   * @memberof IHeroButton
   * @member {string} altTitle
   */
  altTitle: string;
}

/**
 * IHeroImage The Hero Image component
 *
 * @interface IHeroImage
 */
export interface IHeroImage {
  /**
   * The URL of the image to display.
   *
   * @memberof IHeroImage
   * @member {string} url
   */
  url: string;
  /**
   * The alt text to display.
   *
   * @memberof IHeroImage
   * @member {string} [altTitle]
   */
  altTitle?: string;
}

/**
 * IHero
 *
 * @interface IHero
 */
export interface IHero {
  /**
   * The headline to display.
   *
   * @memberof IHero
   * @member {string} headline
   */
  headline: string;
  /**
   * The rich headline to display.
   *
   * @memberof IHero
   * @member {React.ReactNode} [richHeadline]
   */
  richHeadline?: React.ReactNode;
  /**
   * The copy text to display below the headline.
   *
   * @memberof IHero
   * @member {string} [copy]
   */
  copy?: string;
  /**
   * The Background image to display.
   *
   * @memberof IHero
   * @member {IHeroImage} bgImage
   */
  bgImage: IHeroImage;
  /**
   * Color scheme for the hero
   *
   * @memberof IHero
   * @member {'base 100' | 'base 200' | 'base 300' | 'base 400'} color
   */
  color: 'base 100' | 'base 200' | 'base 300' | 'base 400';
  /**
   * Whether to use a variant for the hero
   *
   * @memberof IHero
   * @member {boolean} useVariant
   */
  useVariant: boolean;
  /**
   * The optional first button to display.
   *
   * @memberof IHero
   * @member {IHeroButton} [button1]
   */
  button1?: IHeroButton;
  /**
   * The optional second button to display.
   *
   * @memberof IHero
   * @member {IHeroButton} [button2]
   */
  button2?: IHeroButton;
  /**
   * The optional className.
   *
   * @memberof IHero
   * @member {string} [className]
   */
  className?: string;
  /**
   * The optional wave border color
   *
   * @memberof IHero
   * @member {'base 200' | 'base 300' | 'light green 400' | 'pink 400'} [waveBorderColor]
   */
  waveBorderColor?: 'base 200' | 'base 300' | 'light green 400' | 'pink 400';
  /**
   * The children to display.
   *
   * @memberof IHero
   * @member {React.ReactNode} [children]
   */
  children?: React.ReactNode;
}

/** Background colors */
const bgColors = {
  'base 100': 'bg-base-100',
  'base 200': 'bg-base-200',
  'base 300': 'bg-base-300',
  'base 400': 'bg-base-400',
};

const waveBorders = {
  'base 200': `before:bg-[url('/images/wave-border/horizontal-base-200.svg')] md:before:bg-[url('/images/wave-border/vertical-base-200.svg')]`,
  'base 300': `before:bg-[url('/images/wave-border/horizontal-base-300.svg')] md:before:bg-[url('/images/wave-border/vertical-base-300.svg')]`,
  'light green 400': `before:bg-[url('/images/wave-border/horizontal-light-green-400.svg')] md:before:bg-[url('/images/wave-border/vertical-light-green-400.svg')]`,
  'pink 400': `before:bg-[url('/images/wave-border/horizontal-pink-400.svg')] md:before:bg-[url('/images/wave-border/vertical-pink-400.svg')]`,
};

/**
 * Hero A remix of the flexible hero
 *
 * @param {IHero} props - The props for the Hero component
 * @returns {React.FC<IHero>} Component
 */
const Hero: React.FC<IHero> = ({
  headline,
  richHeadline,
  copy,
  bgImage,
  color,
  useVariant,
  button1,
  button2,
  className = '',
  waveBorderColor,
  children,
}: IHero) => {
  return (
    <div data-testid="hero" className={twMerge('mb-0', className)}>
      <div
        data-testid="hero-wrapper"
        className={clsx(
          `flex md:flex-row ${bgColors[color]}`,
          useVariant ? 'flex-col' : 'flex-col-reverse'
        )}
      >
        <div
          data-testid="hero-variant"
          className={clsx(
            `px-6 sm:px-16 sm:py-12 md:px-10 text-center flex flex-col items-center justify-center relative z-[1] after:${bgColors[color]} min-h-[272px] sm:min-h[208px]`,
            !useVariant
              ? 'md:basis-[46%] py-8'
              : waveBorderColor
              ? waveBorders[waveBorderColor]
              : color === 'base 300' || color === 'base 400'
              ? waveBorders['base 200']
              : waveBorders['base 300'],

            useVariant &&
              `hero-wave hero-wave-gradient-${bgColors[color]} md:basis-[64%] py-12`
          )}
        >
          <Heading
            size="h1"
            className={clsx(
              color === 'base 100' || color === 'base 200'
                ? 'text-mkNavy-400 [&_*]:text-mkNavy-400'
                : 'text-neutral-100 [&_*]:text-neutral-100'
            )}
          >
            {richHeadline || headline}
          </Heading>

          {copy && (
            <Paragraph
              size={useVariant ? 'body1' : 'body3'}
              className={clsx(
                'mt-4 md:mt-6',
                useVariant ? 'mb-6 md:mb-8' : 'mb-8',
                color === 'base 100' || color === 'base 200'
                  ? 'text-neutral-800'
                  : 'text-neutral-100'
              )}
            >
              {copy}
            </Paragraph>
          )}

          {(button1 || button2) && (
            <div
              data-testid="hero-buttons"
              className="flex w-full flex-col sm:flex-row items-center justify-center mt-4 md:mt-6 sm:space-x-4 space-y-4 sm:space-y-0"
            >
              {button1 && (
                <div className="w-full">
                  <Button
                    data-testid="hero-button-1"
                    size="md"
                    type="button"
                    href={button1.url}
                    newTab={button1.target === '_blank'}
                    variant={
                      color === 'base 100' || color === 'base 200'
                        ? 'light'
                        : 'dark'
                    }
                    color="primary"
                    className={clsx(button1 && button2 ? 'w-full' : 'w-auto')}
                  >
                    {button1.label}
                  </Button>
                </div>
              )}
              {button2 && (
                <div className="w-full">
                  <Button
                    data-testid="hero-button-2"
                    size="md"
                    type="button"
                    href={button2.url}
                    newTab={button2.target === '_blank'}
                    variant={
                      color === 'base 100' || color === 'base 200'
                        ? 'light'
                        : 'dark'
                    }
                    color="secondary"
                    className={clsx(button1 && button2 ? 'w-full' : 'w-auto')}
                  >
                    {button2.label}
                  </Button>
                </div>
              )}
            </div>
          )}
          <div
            className={clsx(
              color === 'base 100' && 'hero-wave-vector-variant',
              color === 'base 200' && 'hero-wave-vector-variant2',
              color === 'base 300' || color === 'base 400'
                ? 'hero-wave-vector'
                : ''
            )}
          />

          {children}
        </div>

        {bgImage.url && (
          <div
            className={clsx(
              `w-full relative md:min-h-[540px] md:aspect-auto xl:aspect-[17/10]`,
              useVariant ? 'aspect-square sm:aspect-[74/55]' : 'aspect-[17/10]'
            )}
          >
            <ContentfulImage
              src={bgImage.url}
              alt={bgImage.altTitle}
              layout="fill"
              priority={true}
              className="object-cover"
              data-testid="hero-image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Hero;
