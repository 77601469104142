/**
 * This function is used to strip the base path off of urls
 *
 * @param {string} url - The url to remove the base path from
 * @returns {string} - The url with the base path removed
 */
export const removeBasePath = (url: string): string => {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH;
  if (basePath && url.startsWith(basePath)) {
    return url.replace(basePath, '');
  }
  return url;
};
