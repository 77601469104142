import PageWrapper from '@/components/organisms/PageWrapper/PageWrapper';
import VideoPlayer from '@/components/organisms/VideoPlayer/VideoPlayer';
import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';

/**
 * @param {object} props - VideoPlayerContainerProps
 * @returns {React.ReactNode} - VideoPlayerContainer
 */
export const VideoPlayerContainer = (
  props: ContainerProps['videoPlayer'] & {
    /** Classname */
    className?: string;
  }
) => (
  <PageWrapper classes="page-max-w px-6 sm:px-8 md:px-10">
    <AnimateItem from={{ translateY: 50 }} delay={0.4}>
      <VideoPlayer
        classes={props.className}
        title={props.fields.title}
        video={props.fields.videoUrl}
        alt={props.fields.alt}
        muted={!!props.fields.muted}
        image={addHttps(props.fields.image?.fields.file?.url)}
        loader={contentFulImageLoader}
      />
    </AnimateItem>
  </PageWrapper>
);
