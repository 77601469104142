import { ColorShade } from '@/components/atoms/Colors/Colors';
import Icon, { IconType } from '@/components/atoms/Icon/Icon';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { ToastType } from '@/lib/hooks/toasts/Toast';
import { ReactNode } from 'react';

/**
 * Styling Map Holds all the classes for the particular types of Toast Messages
 *
 * @constant
 */
const stylingMap = {
  success: 'bg-success-200 text-neutral-100',
  error: 'bg-error-200 text-neutral-100',
  warning: 'bg-warning-200 text-neutral-100',
  info: 'bg-info-200 text-neutral-100',
};

/**
 * Icon Map Hold all of the mappings for particular icons and statuses
 *
 * @constant
 */
const iconMap: {
  [key: string]: {
    /** The Icon to use */
    icon: IconType;
    /** The Color Shade to use with the Icon */
    colorShade: ColorShade;
  };
} = {
  success: {
    icon: 'checkCircle',
    colorShade: 100,
  },
  error: {
    icon: 'exclamationCircle',
    colorShade: 100,
  },
  info: {
    icon: 'infoOutline',
    colorShade: 100,
  },
  warning: {
    icon: 'exclamationTriangle',
    colorShade: 100,
  },
};

/** Screen Sizing Holds all the specific Styles for each of the screen sizes */
const mobileSizing = 'right-6 top-16 left-6 max-w-[328px]';
const tabletSizing = 'sm:right-8 sm:top-20 sm:left-8 sm:max-w-[680px]';
const desktopSizing = 'md:right-8 md:top-24 md:left-8 md:max-w-[896px]';

/**
 * IToast Toast Interface
 *
 * @interface IToast
 */
export interface IToast {
  /**
   * Type of Toast to Display
   *
   * @memberof IToast
   * @member {ToastType} type
   */
  type: ToastType;
  /**
   * Should we show the Icon or Not?
   *
   * @memberof IToast
   * @member {boolean} [showIcon]
   */
  showIcon?: boolean;
  /**
   * Close Click Handler
   *
   * @memberof IToast
   * @member {() => void} closeClick
   */
  closeClick: () => void;
  /**
   * Children to be displayed in the Toast
   *
   * @memberof IToast
   * @member {ReactNode} children
   */
  children: ReactNode;
}

/**
 * Toast Toast Component
 *
 * @param {IToast} props - The props for the Toast component
 * @returns {React.FC<IToast>} Toast Component
 */
const Toast: React.FC<IToast> = ({
  type,
  children,
  closeClick,
  showIcon,
}: IToast) => {
  const baseToastClasses = `fixed inline-flex items-center m-auto py-3 inset-x-0 rounded min-h-[48px] z-30 ${mobileSizing} ${tabletSizing} ${desktopSizing}`;
  const toastClasses = `${baseToastClasses} ${stylingMap[type]}`;
  return (
    <div data-testid="toast" className={toastClasses}>
      <div className="inline-flex w-full h-full justify-center items-center">
        {showIcon ? (
          <div className="ml-[18px]" data-testid="toast-icon">
            <Icon
              icon={iconMap[type].icon}
              size={20}
              colorShade={iconMap[type].colorShade}
              colorType="neutral"
            />
          </div>
        ) : null}
        <div className="ml-[18px]">
          <Paragraph className="text-neutral-100" size="body4" styling="bold">
            {children}
          </Paragraph>
        </div>
        <div className="mx-4 ml-auto flex items-center">
          <button data-testid="toast-delete" onClick={() => closeClick()}>
            <span className="sr-only">Dismiss</span>
            <Icon
              icon="close"
              size={25}
              colorShade={iconMap[type].colorShade}
              colorType="neutral"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Toast;
