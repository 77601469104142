import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import FlexibleCard from '@/components/molecules/FlexibleCard/FlexibleCard';
import {
  Options,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import React from 'react';
import { ContainerProps } from '..';

const options: Options = {
  renderNode: {
    /**
     * @param {object} _ - The node
     * @param {string} children - The children
     * @returns {React.ReactNode} - Paragraph Component
     */
    [BLOCKS.PARAGRAPH]: (_, children: React.ReactNode) => (
      <Paragraph size="body4" className="mb-4">
        {children}
      </Paragraph>
    ),
  },
  renderMark: {
    /**
     * @param {string} text - The text to render
     * @returns {React.ReactNode} - The bold text
     */
    bold: (text) => <span className="font-bold">{text}</span>,

    /**
     * @param {string} text - The text to render
     * @returns {React.ReactNode} - The italicized text
     */
    italic: (text) => <span className="font-light italic">{text}</span>,
  },
};

/**
 * @param {object} props - The props for the Flexible Card
 * @returns {React.ReactNode} - The Flexible Card Container
 */
export const FlexibleCardContainer = (
  props: ContainerProps['flexibleCard'] & {
    /** The optional classes for the component */
    className?: string;
  }
) => {
  return (
    <FlexibleCard
      category={props.fields.overline}
      title={props.fields.headline}
      copy={documentToReactComponents(props.fields.body, options)}
      url={props.fields.actionLink?.fields.url || ''}
      label={props.fields.actionLink?.fields.label}
      useVariant={props?.fields.useVariant}
      className={props.className}
    />
  );
};
