import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import { IButton } from '@/components/atoms/Button/Button';
import FullWidthCalloutImage from '@/components/organisms/FullWidthCalloutImage/FullWidthCalloutImage';
import { useEventContext } from '@/lib/hooks/analytics/useEventContext';
import { useOverlay } from '@/lib/hooks/overlayContext/OverlayContext';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import { removeBasePath } from '@/lib/utils/helpers/stringHelpers/removeBasePath';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContainerProps } from '..';

/** Action Link Props */
type ActionLinkProps = IFullWidthCalloutContainerProps['fields']['actionLink1'];

/** Full Width Callout Container props */
export type IFullWidthCalloutContainerProps =
  ContainerProps['fullWidthCalloutImage'] & {
    /** Classname */
    className?: string;
  };

/**
 * @param {IFullWidthCalloutContainerProps} props -
 *   FullWidthCalloutContainerProps
 * @returns {React.ReactNode} - FullWidthCalloutContainer
 */
export const FullWidthCalloutContainer = (
  props: IFullWidthCalloutContainerProps
) => {
  const { updateShow } = useOverlay();
  const { setInitiatingComponent } = useEventContext();

  /**
   * Handles the click event for the action link.
   *
   * @param {ActionLinkProps} actionLink - The action link object.
   * @returns {void} This function does not return a value.
   */
  const handleOnClick = (
    actionLink: IFullWidthCalloutContainerProps['fields']['actionLink1']
  ) => {
    if (actionLink?.fields?.overlay) {
      updateShow(true);
    }

    if (actionLink?.fields?.url?.includes('create-account/start')) {
      setInitiatingComponent('Sign Up Button / All Pages');
    }
  };

  const button1 = props.fields.actionLink1
    ? ({
        children: props.fields.actionLink1?.fields?.label,
        color: 'secondary',
        size: 'md',
        type: 'button',
        variant: 'dark',
        href: props.fields.actionLink1?.fields.overlay
          ? undefined
          : removeBasePath(props.fields.actionLink1?.fields?.url),
        target: props.fields.actionLink1?.fields.overlay
          ? undefined
          : props.fields.actionLink1?.fields?.target,
        /**
         * Executes the onClick event handler.
         *
         * @returns {void} This function does not return a value.
         */
        onClick: () => {
          handleOnClick(props.fields.actionLink1);
        },
      } as IButton)
    : undefined;

  const button2 = props.fields.actionLink2
    ? ({
        children: props.fields.actionLink2?.fields?.label,
        color: 'secondary',
        size: 'md',
        type: 'button',
        variant: 'dark',
        href: props.fields.actionLink2?.fields.overlay
          ? undefined
          : removeBasePath(props.fields.actionLink2?.fields?.url),
        target: props.fields.actionLink2?.fields.overlay
          ? undefined
          : props.fields.actionLink2?.fields?.target,
        /**
         * Executes the onClick event handler.
         *
         * @returns {void} This function does not return a value.
         */
        onClick: () => {
          handleOnClick(props.fields.actionLink2);
        },
      } as IButton)
    : undefined;

  return (
    <FullWidthCalloutImage
      button1={button1}
      button2={button2}
      overline={props.fields.overline}
      body={props.fields.copy}
      bodyRichText={props.fields.copyRichText}
      imageUrl={addHttps(props.fields.image?.fields?.file?.url)}
      title={documentToReactComponents(props.fields?.headline, {
        renderMark: {
          // eslint-disable-next-line jsdoc/require-jsdoc
          bold: (text) => <strong className="font-petco">{text}</strong>,
        },
      })}
      TextWrapper={({ children }) => (
        <AnimateItem delay={0.5} to={{ translateY: -20 }}>
          {children}
        </AnimateItem>
      )}
      loader={contentFulImageLoader}
    />
  );
};
