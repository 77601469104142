import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Button from '@/components/atoms/Button/Button';
import { useOverlay } from '@/lib/hooks/overlayContext/OverlayContext';
import { ContainerProps } from '..';

/**
 * @param {object} props - ActionLinkContainerProps
 * @returns {React.ReactNode} - ActionLinkContainer
 */
export const ActionLinkContainer = (props: ContainerProps['actionLink']) => {
  const { updateShow } = useOverlay();

  return (
    <AnimateItem from={{ translateX: -20 }}>
      <div className="flex items-center justify-center">
        <Button
          color="primary"
          variant="light"
          size="lg"
          href={props.fields.url}
          newTab={props.fields.target === '_blank'}
          aria-label={props.fields.label}
          onClick={() => {
            if (props.fields.overlay) {
              updateShow(true);
            }
          }}
        >
          {props.fields.label}
        </Button>
      </div>
    </AnimateItem>
  );
};
