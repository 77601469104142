import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { accentProps } from '@/lib/constants/constants/accents';
import { useAccent } from '@/lib/hooks/accentContext/AccentContext';
import clsx from 'clsx';
import Link from 'next/link';
import { HTMLAttributeAnchorTarget } from 'react';

/**
 * IFeatureCard Interface for the Feature Card Component
 *
 * @interface IFeatureCard
 */
export interface IFeatureCard {
  /**
   * The Category of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} category
   */
  category: string;
  /**
   * Title of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} title
   */
  title: string;
  /**
   * The Copy of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} copy
   */
  copy: string;
  /**
   * The label of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} label
   */
  label: string;
  /**
   * The Image of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} image
   */
  image: string;
  /**
   * The optional classes for the component
   *
   * @memberof IFeatureCard
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * Url of the Feature Card
   *
   * @memberof IFeatureCard
   * @member {string} url
   */
  url: string;
  /**
   * The optional target of the Card
   *
   * @memberof IFeatureCard
   * @default '_blank'
   * @member {HTMLAttributeAnchorTarget} [target]
   */
  target?: HTMLAttributeAnchorTarget;
}

/**
 * Feature Card
 *
 * @param {IFeatureCard} props - The props for the Feature Card Component
 * @returns {React.FC<IFeatureCard>} Feature Card Component
 */
const FeatureCard: React.FC<IFeatureCard> = ({
  category,
  title,
  copy,
  label,
  image,
  url,
  target = '_blank',
  classes = '',
}: IFeatureCard) => {
  const { accent } = useAccent();

  return (
    <div data-testid="feature-card" className={classes}>
      <Link href={url} className="group" target={target}>
        {image && (
          <div className="aspect-square relative rounded-[20px] overflow-hidden mb-6">
            <ContentfulImage
              data-testid="feature-card-image"
              src={image}
              layout="fill"
              alt={`Image of ${title}`}
              objectFit="cover"
              className="scale-100 aspect-square group-hover:scale-[102%] transition-transform duration-100"
            />
          </div>
        )}
        <div className="w-full">
          <Paragraph size="body5" className="mb-2 text-neutral-700">
            {category}
          </Paragraph>

          <Heading
            size="h3"
            font="petco"
            className="text-mkNavy-400 mb-4 md:mb-6"
          >
            {title}
          </Heading>

          <Paragraph
            size="body3"
            font={accent === accentProps.ORG ? 'amasis' : 'petco'}
          >
            {copy}
          </Paragraph>

          <span
            className={clsx(
              'p-5 font-medium underline underline-offset-[10px] decoration-[3px]',
              {
                'decoration-base-300': accent === accentProps.ORG,
                'decoration-mkPink-400 group-hover:decoration-mkNavy-400':
                  accent === accentProps.ADOPT,
                'decoration-mkLightBlue-400 group-hover:decoration-mkNavy-400':
                  accent === accentProps.CARE,
              }
            )}
          >
            <p
              className={clsx('transition-colors font-bold text-mkNavy-400', {
                'group-hover:text-base-300': accent === accentProps.ORG,
                'group-hover:text-mkNavy-400':
                  accent === accentProps.ADOPT || accent === accentProps.CARE,
              })}
            >
              {label}
            </p>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default FeatureCard;
