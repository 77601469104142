import { ReactNode } from 'react';

/**
 * IPageWrapper
 *
 * @interface IPageWrapper
 */
export interface IPageWrapper {
  /**
   * The content of the page.
   *
   * @memberof IPageWrapper
   * @member {ReactNode} children
   */
  children: ReactNode;
  /**
   * The classes to apply to the component.
   *
   * @memberof IPageWrapper
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
}

/**
 * Page Wrapper Used to wrap the entire page in a fixed size container.
 *
 * @param {IPageWrapper} props - The props for the PageWrapper component
 * @returns {React.FC<IPageWrapper>} PageWrapper Component
 */
const PageWrapper: React.FC<IPageWrapper> = ({
  children,
  classes = '',
}: IPageWrapper) => {
  return <div className={`max-w-[1440px] mx-auto ${classes}`}>{children}</div>;
};

export default PageWrapper;
