/**
 * The special characters that need to be replaced
 *
 * @constant {string}
 */
export const specialChars = 'àáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';

/**
 * The string to replace the special characters with
 *
 * @constant {string}
 */
const specialCharsReplace = 'aaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';

/**
 * Slugify a string
 *
 * @param {string} str - The string to slugify
 * @returns {string} The string as a slug
 */
export default function stringToSlug(str: string): string {
  /**
   * Regex to match special characters
   *
   * @constant {RegExp}
   */
  const specialCharactersRegex = new RegExp(
    specialChars.split('').join('|'),
    'g'
  );
  /**
   * Replace special characters with their replacements
   *
   * @param {string} character - The character to replace
   * @returns {string} The replacement character
   */
  const specialCharsReplacer = (character: string) =>
    specialCharsReplace.charAt(specialChars.indexOf(character));

  return str
    .toString()
    .toLowerCase()
    /** Replace spaces with - */
    .replace(/\s+/g, '-')
    /** Replace special characters matching regex */
    .replace(specialCharactersRegex, specialCharsReplacer)
    /** Replace & with 'and' */
    .replace(/&/g, '-and-')
    /** Remove all non-word chars */
    .replace(/[^\w-]+/g, '')
    /** Replace multiple - with single - */
    .replace(/--+/g, '-')
    /** Trim - from start of text */
    .replace(/^-+/, '')
    /** Trim - from end of text */
    .replace(/-+$/, '');
}
