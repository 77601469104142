import Button, { IButton } from '@/components/atoms/Button/Button';
import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';

/** Background colors */
const bgColors = {
  'base 100': 'bg-base-100',
  'base 200': 'bg-base-200',
  'base 300': 'bg-base-300',
  'base 400': 'bg-base-400',
};

/**
 * IHeroHomepage Interface for Hero Homepage component
 *
 * @interface IHeroHomepage
 */
export interface IHeroHomepage {
  /**
   * The text to display under the title.
   *
   * @memberof IHeroHomepage
   * @member {string} text
   */
  text: string;
  /**
   * The title to display.
   *
   * @memberof IHeroHomepage
   * @member {string | React.ReactNode} title
   */
  title: string | React.ReactNode;
  /**
   * The overline to display.
   *
   * @memberof IHeroHomepage
   * @member {string} [overline]
   */
  overline?: string;
  /**
   * The buttons to display.
   *
   * @memberof IHeroHomepage
   * @member {IButton[]} buttons
   */
  buttons: IButton[];
  /**
   * The background color.
   *
   * @memberof IHeroHomepage
   * @default 'base 100'
   * @member {'base 100' | 'base 200' | 'base 300' | 'base 400'}
   */
  bgColor?: 'base 100' | 'base 200' | 'base 300' | 'base 400';
}

/**
 * Hero Homepage Homepage Hero Component
 *
 * @param {IHeroHomepage} props - The Hero Homepage props.
 * @returns {React.FC<IHeroHomepage>} Hero Homepage Component
 */
const HeroHomepage: React.FC<IHeroHomepage> = ({
  text,
  title,
  overline,
  buttons,
  bgColor = 'base 100',
}: IHeroHomepage) => {
  /**
   * The background images and classes for the component.
   *
   * @constant
   */
  const collarDesktop =
    process.env.NEXT_PUBLIC_CDN_BASE_URL +
    '/assets/lost/homepage-hero/collar-desktop.svg';
  const collarTablet =
    process.env.NEXT_PUBLIC_CDN_BASE_URL +
    '/assets/lost/homepage-hero/collar-tablet.svg';
  const collarMobile =
    process.env.NEXT_PUBLIC_CDN_BASE_URL +
    '/assets/lost/homepage-hero/collar-mobile.svg';
  const dogRunning =
    process.env.NEXT_PUBLIC_CDN_BASE_URL +
    '/assets/lost/homepage-hero/dog-running.svg';

  return (
    <div
      data-testid="hero-homepage"
      className={`flex flex-col h-[calc(100vh-60px)] md:h-[calc(100vh-110px)] min-h-[720px] sm:min-h-[500px] w-full items-center md:justify-center overflow-hidden relative bg-no-repeat bg-contain bg-right-bottom ${bgColors[bgColor]}`}
    >
      <div className="text-center mx-auto z-[2] max-w-[664px] w-full px-6 md:mb-0 mt-16 sm:mt-[15vh] md:mt-0">
        {overline && (
          <Paragraph size="overline" className="uppercase mb-2">
            {overline}
          </Paragraph>
        )}
        <Heading
          size="display1"
          font="petco"
          className="mb-4 text-secondaryBase-400 [&>*]:text-secondaryBase-400"
        >
          {title}
        </Heading>
        <Paragraph size="body3" className="font-petco mb-8">
          {text}
        </Paragraph>
        <div className="w-full space-y-4 sm:space-y-0 sm:space-x-4">
          {buttons.map((ButtonItem, index) => (
            <Button key={index} {...ButtonItem} className="w-full sm:w-auto" />
          ))}
        </div>
      </div>
      <div className="hidden md:flex left-[110px] absolute top-[-110px] overflow-hidden z-[1] pointer-events-none">
        <CdnImage
          src={collarDesktop}
          height={371}
          width={600}
          layout="intrinsic"
          alt="Collar"
          className="absolute bottom-0"
        />
      </div>
      <div className="hidden md:flex right-[0] absolute -bottom-2.5 overflow-hidden z-[1] pointer-events-none">
        <CdnImage
          src={dogRunning}
          height={400}
          width={356}
          layout="intrinsic"
          alt="Dog Running"
          className="absolute bottom-0"
        />
      </div>

      <div className="hidden md:hidden lg:hidden sm:flex right-0 absolute bottom-[30px] overflow-hidden z-[1] pointer-events-none">
        <CdnImage
          src={collarTablet}
          height={480}
          width={600}
          layout="intrinsic"
          alt="Collar"
          className="absolute bottom-0"
        />
      </div>

      <div className="md:hidden lg:hidden sm:hidden right-0 absolute bottom-[30px] overflow-hidden z-[1] pointer-events-none">
        <CdnImage
          src={collarMobile}
          height={350}
          width={350}
          layout="intrinsic"
          alt="Collar"
          className="absolute bottom-0"
        />
      </div>
    </div>
  );
};

export default HeroHomepage;
