import Link from 'next/link';

/**
 * IButtonPreview
 *
 * @interface IButtonPreview
 */
export interface IButtonPreview {
  /**
   * The optional text to display
   *
   * @memberof IButtonPreview
   * @default 'Exit Preview'
   * @member {string} [text]
   */
  text?: string;
}

/**
 * ButtonPreview A button to exit preview mode
 *
 * @param {IButtonPreview} props - The props for the ButtonPreview component
 * @returns {React.FC<IButtonPreview>} Component
 */
const ButtonPreview: React.FC<IButtonPreview> = ({
  text = 'Exit Preview',
}: IButtonPreview) => {
  return (
    <Link
      href="/api/preview/clear"
      prefetch={false}
      className="text-sm fixed right-10 z-50 bg-nextdoor-100 px-4 py-2 rounded-full font-bold"
      data-testid="button-preview"
    >
      {text}
    </Link>
  );
};

export default ButtonPreview;
