import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import { contentful } from '@/lib/dataSource/contentful/client';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { AccordionFaqContainer } from '@/lib/dataSource/contentful/containers/AccordionFaqContainer/AccordionFaqContainer';
import { BlockContainer } from '@/lib/dataSource/contentful/containers/BlockContainer/BlockContainer';
import { CardContainer } from '@/lib/dataSource/contentful/containers/CardContainer/CardContainer';
import { CarouselContainer } from '@/lib/dataSource/contentful/containers/CarouselContainer/CarouselContainer';
import { DisplayTextContainer } from '@/lib/dataSource/contentful/containers/DisplayTextContainer/DisplayTextContainer';
import { FeatureCardContainer } from '@/lib/dataSource/contentful/containers/FeatureCardContainer/FeatureCardContainer';
import { FlexibleCardContainer } from '@/lib/dataSource/contentful/containers/FlexibleCardContainer/FlexibleCardContainer';
import { FullWidthCalloutContainer } from '@/lib/dataSource/contentful/containers/FullWidthCalloutContainer/FullWidthCalloutContainer';
import { HeroContainer } from '@/lib/dataSource/contentful/containers/HeroContainer/HeroContainer';
import { HeroHomeContainer } from '@/lib/dataSource/contentful/containers/HeroHomeContainer/HeroHomeContainer';
import { HeroIllustrationContainer } from '@/lib/dataSource/contentful/containers/HeroIllustrationContainer/HeroIllustrationContainer';
import { IconCardContainer } from '@/lib/dataSource/contentful/containers/IconCardContainer/IconCardContainer';
import { ImageContent1By1Container } from '@/lib/dataSource/contentful/containers/ImageContent1By1Container/ImageContent1By1Container';
import { LogoGridContainer } from '@/lib/dataSource/contentful/containers/LogoGridContainer/LogoGridContainer';
import { RelatedArticlesContainer } from '@/lib/dataSource/contentful/containers/RelatedArticlesContainer/RelatedArticlesContainer';
import { ReunionCardContainer } from '@/lib/dataSource/contentful/containers/ReunionCardContainer/ReunionCardContainer';
import { RichTextContainer } from '@/lib/dataSource/contentful/containers/RichTextContainer/RichTextContainer';
import { SeparatorContainer } from '@/lib/dataSource/contentful/containers/SeparatorContainer/SeparatorContainer';
import { SideBySideContainer } from '@/lib/dataSource/contentful/containers/SideBySideContainer/SideBySideContainer';
import { SplitContentContainer } from '@/lib/dataSource/contentful/containers/SplitContentContainer/SplitContentContainer';
import { TeamCardContainer } from '@/lib/dataSource/contentful/containers/TeamCardContainer/TeamCardContainer';
import { TypeFormContainer } from '@/lib/dataSource/contentful/containers/TypeFormContainer/TypeFormContainer';
import { VideoPlayerContainer } from '@/lib/dataSource/contentful/containers/VideoPlayerContainer/VideoPlayerContainer';
import { Custom } from '@/lib/dataSource/contentful/models/layouts/custom';
import { Topics } from '@/lib/dataSource/contentful/models/topics';
import { Fragment } from 'react';

/** Simulates a "Custom Page" response from Contentful */
export type CustomPageResponse = Awaited<
  ReturnType<
    typeof contentful.withoutUnresolvableLinks.getEntry<
      Custom['LostCustomPage']
    >
  >
>;

/** The Custom Page's Elements union type to be resolved in the iterable */
type PageElementProps = CustomPageResponse['fields']['elements'][number] & {
  /** The key for the JSX.Element */
  key: string;
};

/** Simulates a "Block" response from Contentful */
export type BlockResponse = Awaited<
  ReturnType<
    typeof contentful.withoutUnresolvableLinks.getEntry<Topics['Block']>
  >
>;

/** The Block's Elements union type to be resolved in the iterable */
type BlockElementProps = NonNullable<
  BlockResponse['fields']['elements']
>[number];

/** Assembly Factory */
class AssemblyFactory {
  /** The map of page elements */
  private _pageElementsMap = {
    /**
     * @param {PageElementProps} props - The props for the Video Player
     * @returns {React.ReactNode} - The Video Player
     */
    videoPlayer: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <VideoPlayerContainer
          key={key}
          {...(rest as ContainerProps['videoPlayer'])}
        />
      );
    },
    /**
     * @param {PageElementProps} props - The props for the Rich Text
     * @returns {React.ReactNode} - The Rich Text
     */
    richText: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <AnimateItem key={key} from={{ translateY: 20 }}>
          <RichTextContainer {...(rest as ContainerProps['richText'])} />
        </AnimateItem>
      );
    },

    /**
     * @param {PageElementProps} props - The props for the FAQ
     * @returns {React.ReactNode} - The FAQ
     */
    faq: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <AccordionFaqContainer
          key={key}
          {...(rest as ContainerProps['faq'])}
          className="py-0"
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Separator
     * @returns {React.ReactNode} - Separator
     */
    separator: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <SeparatorContainer
          key={key}
          {...(rest.fields as ContainerProps['separator']['fields'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Hero
     * @returns {React.ReactNode} - The Hero
     */
    hero: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return <HeroContainer key={key} {...(rest as ContainerProps['hero'])} />;
    },

    /**
     * @param {PageElementProps} props - The props for the HeroHome
     * @returns {React.ReactNode} - The HeroHome
     */
    heroHome: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <HeroHomeContainer
          key={key}
          {...(rest as ContainerProps['heroHome'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the FAQ
     * @returns {React.ReactNode} - The FAQ
     */
    heroIllustration: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <HeroIllustrationContainer
          key={key}
          {...(rest as ContainerProps['heroIllustration'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the carousel
     * @returns {React.ReactNode} - The Carousel
     */
    carousel: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <CarouselContainer
          key={key}
          {...(rest as ContainerProps['carousel'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the FAQ
     * @returns {React.ReactNode} - The FAQ
     */
    sideBySide: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <SideBySideContainer
          key={key}
          {...(rest as ContainerProps['sideBySide'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Full Width Callout
     * @returns {React.ReactNode} - The Full Width Callout
     */
    fullWidthCalloutImage: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <FullWidthCalloutContainer
          key={key}
          {...(rest as ContainerProps['fullWidthCalloutImage'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Display Text
     * @returns {React.ReactNode} - The Display Text
     */
    displayText: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <DisplayTextContainer
          key={key}
          {...(rest as ContainerProps['displayText'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Block
     * @returns {React.ReactNode} - The Block
     */
    block: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <BlockContainer key={key} {...(rest as ContainerProps['block'])} />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the Block
     * @returns {React.ReactNode} - The Block
     */
    relatedArticles: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <RelatedArticlesContainer
          key={key}
          {...(rest as ContainerProps['relatedArticles'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props for the SplitContent
     * @returns {React.ReactNode} - The SplitContent
     */
    splitContent: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <SplitContentContainer
          key={key}
          {...(rest as ContainerProps['splitContent'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props.
     * @returns {React.ReactNode} - The component
     */
    logoGrid: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <LogoGridContainer
          key={key}
          {...(rest as ContainerProps['logoGrid'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props.
     * @returns {React.ReactNode} - The component
     */
    imageContent1By1: (props: PageElementProps) => {
      const { key, ...rest } = props;
      return (
        <ImageContent1By1Container
          key={key}
          {...(rest as ContainerProps['imageContent1By1'])}
        />
      );
    },

    /**
     * @param {PageElementProps} props - The props.
     * @returns {React.ReactNode} - The component
     */
    typeForm: (props: PageElementProps) => {
      const { key, ...rest } = props;

      return (
        <TypeFormContainer
          key={key}
          {...(rest as ContainerProps['typeForm'])}
        />
      );
    },
  };

  /** The map of block elements */
  private _blockElementsMap = {
    /**
     * @param {BlockElementProps} props - The props for the Card
     * @returns {React.ReactNode} - The Card
     */
    card: (props: BlockElementProps) => (
      <CardContainer {...(props as ContainerProps['card'])} />
    ),

    /**
     * @param {BlockElementProps} props - The props for the Feature Card
     * @returns {React.ReactNode} - The Feature Card
     */
    featureCard: (props: BlockElementProps) => (
      <FeatureCardContainer {...(props as ContainerProps['featureCard'])} />
    ),

    /**
     * @param {BlockElementProps} props - The props for the Flexible Card
     * @returns {React.ReactNode} - The Flexible Card
     */
    flexibleCard: (props: BlockElementProps) => (
      <FlexibleCardContainer {...(props as ContainerProps['flexibleCard'])} />
    ),

    /**
     * @param {BlockElementProps} props - The props for the Team Card
     * @returns {React.ReactNode} - The Team Card
     */
    teamCard: (props: BlockElementProps) => (
      <TeamCardContainer {...(props as ContainerProps['teamCard'])} />
    ),

    /**
     * @param {BlockElementProps} props - The props for the Reunion Card
     * @returns {React.ReactNode} - The Reunion Card
     */
    reunionCard: (props: BlockElementProps) => (
      <ReunionCardContainer {...(props as ContainerProps['reunionCard'])} />
    ),
    /**
     * @param {BlockElementProps} props - The props for the Display Text
     * @returns {React.ReactNode} - The Display Text
     */
    displayText: (props: BlockElementProps) => (
      <DisplayTextContainer {...(props as ContainerProps['displayText'])} />
    ),

    /**
     * @param {BlockElementProps} props - The props for the Icon Card
     * @returns {React.ReactNode} - The Icon Card
     */
    iconCard: (props: BlockElementProps) => (
      <IconCardContainer {...(props as ContainerProps['iconCard'])} />
    ),
  };

  /**
   * @param {PageElementProps[]} nodes - The nodes to iterate over
   * @returns {React.ReactNode[]} - The JSX.Elements
   */
  public createPageElements(nodes: CustomPageResponse['fields']['elements']) {
    return (
      <>
        {nodes?.map((node, idx) => {
          if (node?.sys.contentType.sys.id)
            return this._pageElementsMap[node.sys.contentType.sys.id]?.({
              ...node,
              key: node.sys.id + idx,
            });
        })}
        <SeparatorContainer color="white" size="default" />
      </>
    );
  }

  /**
   * @param {BlockElementProps[]} nodes - The nodes to iterate over
   * @param {boolean} addAnimation - Whether to add the animation
   * @returns {React.ReactNode[]} - The JSX.Elements
   */
  public createBlockElements(
    nodes: BlockResponse['fields']['elements'],
    addAnimation = true
  ) {
    return nodes?.map((node, idx) => {
      if (node?.sys.contentType.sys.id)
        return addAnimation ? (
          <AnimateItem
            key={node.sys.id}
            from={{ translateY: 20 }}
            delay={(idx * 3) / 10}
            className="w-full"
          >
            {this._blockElementsMap[node.sys.contentType.sys.id]?.(node)}
          </AnimateItem>
        ) : (
          <Fragment key={node.sys.id}>
            {this._blockElementsMap[node.sys.contentType.sys.id]?.(node)}
          </Fragment>
        );
    });
  }
}

export const assemblyFactory = new AssemblyFactory();
