import CardArticle from '@/components/molecules/CardArticle/CardArticle';
import CardImage from '@/components/molecules/CardImage/CardImage';
import InfoGraphicCard from '@/components/molecules/InfoGraphicCard/InfoGraphicCard';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '..';

/**
 * @param {object} props - The props for the Card
 * @returns {React.ReactNode} - The Card Container
 */
export const CardContainer = (props: ContainerProps['card']) => {
  const { title, image, actionLink, componentStyle } = props.fields;

  const copy = props.fields.copy || '';
  const copyRichText = props.fields.copyRichText;
  const href = actionLink?.fields.url || '';

  switch (componentStyle) {
    case 'Card Article':
      return (
        <CardArticle
          title={title}
          excerpt={copy}
          image={addHttps(image?.fields?.file?.url)}
          url={actionLink?.fields.url || ''}
          loader={contentFulImageLoader}
        />
      );

    case 'Card Image':
      return (
        <CardImage
          alt={title}
          image={addHttps(image?.fields?.file?.url)}
          aspectRatio="1:1"
          href={href}
          text={title}
          classes="rounded-2xl overflow-hidden [&_p]:font-semibold"
          loader={contentFulImageLoader}
        />
      );

    case 'Info Graphic Card':
      return (
        <div className="[&_span.text-h5]:font-semibold [&>div]:px-0">
          <InfoGraphicCard
            image={addHttps(image?.fields?.file?.url)}
            title={title}
            description={copy}
            descriptionRichText={copyRichText}
            noLoader
          />
        </div>
      );
  }
};
