import CarouselItemReunion from '@/components/molecules/CarouselItemReunion/CarouselItemReunion';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '..';

/**
 * @param {ContainerProps['reunionCard']} props - ReunionCardContainer props
 * @returns {React.ReactNode} - ReunionCardContainer component
 */
export const ReunionCardContainer = (props: ContainerProps['reunionCard']) => (
  <CarouselItemReunion
    key={props.sys.id}
    petName={props.fields.petName}
    ownerName={props.fields.ownerName}
    description={props.fields.copy}
    image={addHttps(props.fields.image?.fields.file?.url)}
    link={props.fields.actionLink?.fields.url || ''}
  />
);
