import { IBlobListItem } from './BlobList';

/**
 * @param {IBlobListItem} props - The props for the Blob component
 * @returns {React.FC<IBlobListItem>} Blob Component
 */
const BlobTwo: React.FC<IBlobListItem> = ({
  size,
  color,
  id = 'blob-two',
}: IBlobListItem) => {
  return (
    <svg
      height={size}
      id={id}
      data-testid="svg-blob-two"
      viewBox="0 0 316 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M231.5 31.0411C231.5 60.3122 202.189 110.041 168.5 110.041C134.811 110.041 84 114.812 84 85.541C84 56.2699 134.811 4.04098 168.5 4.04098C202.189 4.04098 231.5 1.76997 231.5 31.0411Z"
        fill={color}
      />
    </svg>
  );
};

export default BlobTwo;
