import Icon from '@/components/atoms/Icon/Icon';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import stringToSlug from '@/lib/utils/helpers/legacy/stringToSlug';
import { Disclosure } from '@headlessui/react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

/**
 * IAccordionContent Interface for the Accordion Content Item
 *
 * @interface IAccordionContentItem
 */
export interface IAccordionContentItem {
  /**
   * The header of the Accordion Content Item
   *
   * @memberof IAccordionContentItem
   * @member {string} header
   */
  header: string;
  /**
   * The content of the Accordion Content Item
   *
   * @memberof IAccordionContentItem
   * @member {string | React.ReactNode} [content]
   */
  content?: string | React.ReactNode;
  /**
   * Component is rendered open initially
   *
   * @memberof IAccordionContentItem
   * @member {boolean} [startOpen]
   */
  startOpen?: boolean;
}

/**
 * IAccordionContent Interface for the Accordion Content Component
 *
 * @interface IAccordionContent
 */
export interface IAccordionContent {
  /**
   * An array of AccordionContent objects containing a header and content
   *
   * @memberof IAccordionContent
   * @member {IAccordionContentItem[]} content
   */
  content: IAccordionContentItem[];
  /**
   * The classNames
   *
   * @memberof IAccordionContent
   * @member {string} [className]
   */
  className?: string;
  /**
   * Function to set the collapse state
   *
   * @memberof IAccordionContent
   * @member {(id: string, close: () => void) => void} [setCollapse]
   */
  setCollapse?: (id: string, close: () => void) => void;
}

/**
 * Accordion Content Accordion molecule for displaying Accordion Content
 *
 * @param {IAccordionContent} props - The props for the Accordion Content
 * @returns {React.FC<IAccordionContent>} AccordionContent Component
 */
const AccordionContent: React.FC<IAccordionContent> = ({
  content,
  className = '',
  setCollapse,
}: IAccordionContent) => {
  // Base Button Classes to apply to accordion buttons
  const baseButtonClass =
    'text-body4 w-full h-full font-petco inline-flex justify-between text-left font-bold focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-focus-400 items-center';

  return (
    <div
      data-testid="accordion-content"
      className={twMerge(
        clsx(
          'border-b-solid border-b border-b-neutral-300 border-t-solid border-t border-t-neutral-300 accordion-item',
          className
        )
      )}
    >
      {content.map((contentItem, index) => (
        <Disclosure defaultOpen={contentItem.startOpen} as="div" key={index}>
          {({ open, close }) => {
            setCollapse?.(stringToSlug(contentItem.header), close);

            return (
              <>
                <Disclosure.Button
                  data-testid={`accordion-content-disclosure-btn-${index}`}
                  className={`${baseButtonClass} ${
                    open ? 'pt-4 pb-1' : 'py-4'
                  } `}
                >
                  {contentItem.header}
                  <Icon
                    icon={open ? 'arrowUp' : 'arrowDown'}
                    size={24}
                    colorType="base"
                    colorShade={400}
                  />
                </Disclosure.Button>
                <Disclosure.Panel
                  data-testid={`accordion-content-disclosure-panel-${index}`}
                  className="whitespace-pre-line"
                >
                  {/* If the content is a React Node, render it as is */}
                  {typeof contentItem.content === 'object' &&
                    contentItem.content}
                  {/* If the content is a string, render it as a Paragraph */}
                  {typeof contentItem.content === 'string' && (
                    <Paragraph size="body4" className="pr-8 pb-4">
                      {contentItem.content}
                    </Paragraph>
                  )}
                </Disclosure.Panel>
              </>
            );
          }}
        </Disclosure>
      ))}
    </div>
  );
};

export default AccordionContent;
