import ImageContent1by1 from '@/components/organisms/ImageContent1by1/ImageContent1by1';
import { useOverlay } from '@/lib/hooks/overlayContext/OverlayContext';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import {
  RenderNode,
  documentToReactComponents,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { ContainerProps } from '..';
import { renderBreakLine } from '@/lib/utils/stringReplace/renderBreakLine';

const renderOptions = {
  renderNode: {
    /**
     * Embedded Asset
     *
     * @param {Document} node - The node
     * @param {string} children - The children
     * @returns {React.ReactElement} - Image Component
     */
    [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
      <p className="text-neutral-800 mb-6 lg:mb-10 md:line-clamp-6 lg:line-clamp-[20] text-body3">
        {children}
      </p>
    ),
  } as unknown as RenderNode,
  renderText: renderBreakLine,
};

/**
 * @param {object} props - ImageContent1By1ContainerProps
 * @returns {React.ReactNode} - ImageContent1By1Container
 */
export const ImageContent1By1Container = (
  props: ContainerProps['imageContent1By1'] & {
    /** ClassName */
    className?: string;
  }
) => {
  const { updateShow } = useOverlay();

  const button = props.fields.actionLink
    ? {
        label: props.fields.actionLink.fields.label,
        altTitle: props.fields.actionLink.fields.altTitle,

        url: props.fields.actionLink?.fields.overlay
          ? '#'
          : props.fields.actionLink?.fields?.url,
        target: props.fields.actionLink?.fields.overlay
          ? undefined
          : props.fields.actionLink?.fields?.target,
        /**
         * Executes the onClick event handler.
         *
         * @param {React.MouseEvent} event - The event
         * @returns {void} This function does not return a value.
         */
        onClick: (event: React.MouseEvent) => {
          if (props.fields.actionLink?.fields.overlay) {
            event.preventDefault();
            updateShow(true);
          }
        },
      }
    : undefined;

  return (
    <ImageContent1by1
      className={props.className}
      overline={props.fields.overline}
      headline={documentToReactComponents(props.fields.headline, {
        renderMark: {
          // eslint-disable-next-line jsdoc/require-jsdoc
          bold: (text) => <span className="font-bold">{text}</span>,
          // eslint-disable-next-line jsdoc/require-jsdoc
          italic: (text) => (
            <span className="font-light font-amasis italic">{text}</span>
          ),
        },
      })}
      copy={
        props.fields.copyRichText
          ? documentToReactComponents(props.fields.copyRichText, renderOptions)
          : props.fields.copy
      }
      imageSrc={addHttps(props.fields.image?.fields.file?.url)}
      imageAlt={props.fields.image?.fields.title}
      button={button}
      orientation={props.fields.orientation}
      hideBackground={props.fields.hideBackground}
    />
  );
};
