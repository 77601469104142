import { tomorrow } from '@/lib/utils/helpers/dateHelpers/dates';
import LocalStorage from '@/lib/utils/storage/local-storage';
import { useEffect } from 'react';
import { Toast, ToastType, useToastContext } from './Toast';

/** Props for the buildToastPayload fn */
type BuildToastPayloadProps = {
  /** Toast id */
  id?: string;
  /** Toast type */
  type: ToastType;
  /** Toast message */
  message: string;
};

/**
 * Generates a toast payload for the local storage
 *
 * @param {object} param - Function params
 * @param {string} [param.id] - The toast id
 * @param {ToastType} param.type - The toast type
 * @param {string} param.message - The toast message
 * @returns {object} - The built toast payload
 */
const buildToastPayload = ({
  id = '1',
  type,
  message,
}: BuildToastPayloadProps) => {
  return {
    id,
    type: type,
    message: message,
    persistent: false,
    showIcon: true,
  };
};

const STORAGE_TOAST_KEY = 'toast';

/**
 * Saves the toast payload to local storage
 *
 * @returns {Function} A function to save toast info into the local storage
 */
export const useSaveToastToStorage = () => {
  const storage = new LocalStorage();

  return (type: ToastType, message: string, id?: string) =>
    storage.set(
      STORAGE_TOAST_KEY,
      buildToastPayload({ id, type, message }),
      tomorrow
    );
};

/**
 * Looks for the STORAGE_TOAST_KEY in local storage and dispatches a new toast
 * if that's the case.
 */
export const useCheckAndDispatchToastFromStorage = () => {
  const storage = new LocalStorage();
  const toast = storage.get(STORAGE_TOAST_KEY) as Toast | null;

  const { dispatch } = useToastContext();

  useEffect(() => {
    if (toast) {
      dispatch({
        type: 'ADD_TOAST',
        toast,
      });

      storage.delete('toast');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(toast)]);
};
