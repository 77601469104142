import { useEffect, useRef, useState } from 'react';

/**
 * Returns a reference and a state that changes when the component is clicked
 * outside
 *
 * @param {boolean} initialIsFocused - Initial focus state
 * @returns {object} - Object with ref and isFocused state
 */
export function useComponentFocused(initialIsFocused: boolean) {
  const [isComponentFocused, setIsComponentFocused] =
    useState(initialIsFocused);

  const ref = useRef<HTMLInputElement>(null);

  /**
   * Handles click outside of the component
   *
   * @param {MouseEvent} event - The event object
   * @returns {void}
   */
  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentFocused(false);
    } else {
      setIsComponentFocused(true);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentFocused, setIsComponentFocused };
}
