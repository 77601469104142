import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import DisplayText from '@/components/molecules/DisplayText/DisplayText';
import PageWrapper from '@/components/organisms/PageWrapper/PageWrapper';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import { ContainerProps } from '..';

/**
 * @param {object} props - DisplayTextContainerProps
 * @returns {React.ReactNode} - DisplayTextContainer
 */
export const DisplayTextContainer = (
  props: ContainerProps['displayText'] & {
    /** Classname */
    className?: string;
  }
) => {
  const widthClass = (() => {
    const map: Record<'Full' | 'Wide' | 'Thin', string> = {
      Full: 'w-full',
      Wide: 'max-w-[446px] md:max-w-[896px]',
      Thin: 'max-w-[446px] md:max-w-[664px]',
    };

    return props.fields.width ? map[props.fields.width] : '';
  })();

  return (
    <PageWrapper classes="page-max-w">
      <AnimateItem from={{ translateY: 20 }} duration={0.65}>
        <div
          className={twMerge(
            clsx(
              'mx-auto flex flex-col items-center',
              widthClass,
              props.className
            )
          )}
        >
          <DisplayText
            heading={documentToReactComponents(props.fields.headline, {
              renderMark: {
                /**
                 * Bold
                 *
                 * @param {string} text - The text to be bolded
                 * @returns {React.ReactElement} Component
                 */
                bold: (text) => (
                  <strong className="text-mkPurple-400 font-petco">
                    {text}
                  </strong>
                ),
              },
            })}
            overline={props.fields.overline}
          />
        </div>
      </AnimateItem>
    </PageWrapper>
  );
};
