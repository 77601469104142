import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Button, { IButton } from '@/components/atoms/Button/Button';
import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { anchorTarget } from '@/lib/utils/anchorTarget';
import { renderBreakLine } from '@/lib/utils/stringReplace/renderBreakLine';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES, MARKS } from '@contentful/rich-text-types';
import Image, { ImageLoader } from 'next/legacy/image';
import React from 'react';

/**
 * Base Class
 *
 * @constant
 */
const baseClass = `text-neutral-100 text-center`;

const options: Options = {
  renderNode: {
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Paragraph
        size="body4"
        className={`${baseClass} text-center mb-6 lg:mb-10`}
      >
        {children}
      </Paragraph>
    ),

    /**
     * @param {object} node - Node Data
     * @param {object} node.data - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode | void} - ReactNode
     */
    [INLINES.HYPERLINK]: ({ data }, children) => {
      // Only process youtube links
      if (data.uri) {
        return anchorTarget(data.uri, children);
      }
    },
  },
  renderMark: {
    /**
     * Bold text
     *
     * @param {React.ReactNode} text - Text to render
     * @returns {React.ReactNode} - The formatted text
     */
    [MARKS.BOLD]: (text) => <strong className="font-petco">{text}</strong>,
    /**
     * Italic text
     *
     * @param {React.ReactNode} text - Text to render
     * @returns {React.ReactNode} - The formatted text
     */
    [MARKS.ITALIC]: (text) => <em className="font-petco">{text}</em>,
  },
  renderText: renderBreakLine,
};

/**
 * IWrapper Interface for React components that only receives 1 children as a
 * prop.
 *
 * @interface IWrapper
 */
export interface IWrapper {
  /**
   * - React children passed via props.
   *
   * @memberof IWrapper
   * @member {React.ReactNode} [children]
   */
  children?: React.ReactNode;
}

/**
 * IFullWidthCallout Interface for Full Width Callout component
 *
 * @interface IFullWidthCalloutImage
 */
export interface IFullWidthCalloutImage {
  /**
   * The overline text to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {string} [overline]
   */
  overline?: string;
  /**
   * The headline text to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {React.ReactNode | string} [title]
   */
  title?: React.ReactNode | string;
  /**
   * The content text to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {string | React.ReactNode} [body]
   */
  body?: string | React.ReactNode;
  /**
   * The content text to display with RichText support.
   *
   * @memberof IFullWidthCalloutImage
   * @member {Document} [bodyRichText]
   */
  bodyRichText?: Document;
  /**
   * The first button to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {IButton} [button1]
   */
  button1?: IButton;
  /**
   * The second button to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {IButton} [button2]
   */
  button2?: IButton;
  /**
   * The image url to display.
   *
   * @memberof IFullWidthCalloutImage
   * @member {string} imageUrl
   */
  imageUrl: string;
  /**
   * The opacity of the image. 0-1
   *
   * @memberof IFullWidthCalloutImage
   * @member {number} [imageOpacity]
   */
  imageOpacity?: number;
  /**
   * The optional classes
   *
   * @memberof IFullWidthCalloutImage
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * A JSX Element to wrap the text and style it.
   *
   * @memberof IFullWidthCalloutImage
   * @member {React.FC<IWrapper>} [TextWrapper]
   */
  TextWrapper?: React.FC<IWrapper>;
  /**
   * The loader function for the image
   *
   * @memberof IFullWidthCalloutImage
   * @member {ImageLoader} [loader]
   */
  loader?: ImageLoader;
}

/**
 * Full Width Callout Full width callout image component. Takes in overline,
 * headline, content and buttons
 *
 * @param {IFullWidthCalloutImage} props - The props of the Full Width Callout
 * @returns {React.FC<IFullWidthCalloutImage>} Full Width Callout Component
 */
const FullWidthCalloutImage: React.FC<IFullWidthCalloutImage> = ({
  overline,
  title,
  body,
  bodyRichText,
  button1,
  button2,
  imageUrl,
  imageOpacity = 0.5,
  classes = '',
  TextWrapper,
  loader,
}: IFullWidthCalloutImage) => {
  const firstButton = button1 ? button1 : null;
  const secondButton = button2 ? button2 : null;
  const Wrapper = TextWrapper
    ? TextWrapper
    : ({ children }: IWrapper) => (
        <AnimateItem from={{ translateY: 20 }}>{children}</AnimateItem>
      );

  return (
    <div
      className={`w-full flex justify-center bg-black ${classes}`}
      data-testid="full-width-callout"
    >
      <div className="w-full py-24 md:py-[168px] relative flex justify-center px-6 sm:px-8">
        <div className="z-[1]">
          <Wrapper>
            {overline && (
              <Paragraph
                size="body5"
                className={`${baseClass} capitalize mb-2 md:mb-4`}
              >
                {overline}
              </Paragraph>
            )}
            {title && (
              <Heading
                size="h2"
                font="amasis"
                className={`${baseClass} text-center mb-2 md:mb-4 [&>*]:text-neutral-100`}
              >
                {title}
              </Heading>
            )}

            {bodyRichText && documentToReactComponents(bodyRichText, options)}

            {!bodyRichText && body && (
              <Paragraph size="body4" className={`${baseClass} text-center`}>
                {body}
              </Paragraph>
            )}

            <div className="mt-6 lg:mt-8 flex flex-wrap justify-center">
              {firstButton && (
                <Button
                  {...firstButton}
                  data-testid="first-button"
                  className="mb-4 mx-2"
                />
              )}
              {secondButton && (
                <Button
                  {...secondButton}
                  data-testid="second-button"
                  className="mb-4 mx-2"
                />
              )}
            </div>
          </Wrapper>
        </div>

        {loader ? (
          <Image
            src={imageUrl}
            layout="fill"
            objectFit="cover"
            alt={'Full Width Callout Image'}
            objectPosition="center"
            style={{
              opacity: imageOpacity,
            }}
            loader={loader}
          ></Image>
        ) : (
          <CdnImage
            src={imageUrl}
            layout="fill"
            objectFit="cover"
            alt="Full Width Callout Image"
            objectPosition="center"
            style={{
              opacity: imageOpacity,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default FullWidthCalloutImage;
