import FeatureCard from '@/components/molecules/FeatureCard/FeatureCard';
import { addHttps } from '@/lib/utils/stringReplace/addHttps'; 
import { ContainerProps } from '..';

/**
 * @param {ContainerProps['featureCard']} props - FeatureCardContainer props 
 * @returns {React.ReactNode} - FeatureCardContainer
 */
export const FeatureCardContainer = (props: ContainerProps['featureCard']) => (
  <FeatureCard
    category={props.fields.overline}
    title={props.fields.headline}
    copy={props.fields.copy}
    image={addHttps(props.fields.image?.fields.file?.url)}
    url={props.fields.actionLink?.fields.url || ''}
    label={props.fields.actionLink?.fields.label || ''}
    target={props.fields.actionLink?.fields.target}
  />
);
