import Divider from '@/components/atoms/Divider/Divider';
import ArticlesDisplay from '@/components/organisms/ArticlesDisplay/ArticlesDisplay';
import contentFulImageLoader from '@/lib/loaders/contentfulImageLoader';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '..';

/**
 * @param {ContainerProps['relatedArticles']} props -
 *   RelatedArticlesContainerProps
 * @returns {React.ReactNode} - RelatedArticlesContainer
 */
export const RelatedArticlesContainer = (
  props: ContainerProps['relatedArticles']
) => {
  const { articles } = props.fields;

  const relatedArticles = articles.length
    ? articles.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (article: any) =>
          article && {
            title: article.fields.headline,
            excerpt: article.fields.excerpt,
            image: addHttps(article?.fields.featuredImage?.fields.file?.url),
            url: `/articles/${article?.fields.slugs}`,
            loader: contentFulImageLoader,
          }
      )
    : [];

  if (!relatedArticles.length) {
    return null;
  }

  return (
    <div className="page-max-w" data-testid="related-articles-container">
      <Divider type="lightGrey" classes={`my-12 md:my-20`} />

      <ArticlesDisplay
        title="Related Articles"
        articles={relatedArticles}
        buttonLink={{
          children: 'View All Articles',
          href: 'https://petcolove.org/lost/articles',
          variant: 'light',
        }}
      />
    </div>
  );
};
