import BlankLayout from '@/components/layouts/BlankLayout/BlankLayout';
import MinimalLayout from '@/components/layouts/MinimalLayout/MinimalLayout';
import NoFooterLayout from '@/components/layouts/NoFooterLayout/NoFooterLayout';
import PrimaryLayout from '@/components/layouts/PrimaryLayout/PrimaryLayout';
import ButtonPreview from '@/components/molecules/ButtonPreview/ButtonPreview';
import HeadTemplate from '@/components/templates/Head/Head';
import { assemblyFactory } from '@/lib/dataSource/contentful/assemblyFactory';
import { getClient } from '@/lib/dataSource/contentful/client';
import {
  getContentfulLocale,
  InferContentfulResponse,
} from '@/lib/dataSource/contentful/helpers';
import { Custom } from '@/lib/dataSource/contentful/models/layouts/custom';
import { Topics } from '@/lib/dataSource/contentful/models/topics';
import {
  ContentfulLivePreviewProvider,
  useContentfulLiveUpdates,
} from '@contentful/live-preview/react';
import {
  GetServerSidePropsContext,
  InferGetServerSidePropsType,
  NextPage,
} from 'next';
import { ReactElement, ReactNode } from 'react';

/** The props for the Custom Page */
export type CustomPageProps = InferGetServerSidePropsType<
  typeof getServerSideProps
>;

/**
 * Custom Page
 *
 * @param {CustomPageProps} props - The props for the Custom Page
 * @returns {React.FC<CustomPageProps>} - The Custom Page
 */
const CustomPage: NextPage = (props: CustomPageProps) => {
  const updatedEntries = useContentfulLiveUpdates(props);

  return (
    <ContentfulLivePreviewProvider locale="en-US">
      <div data-testid="custom-page">
        <HeadTemplate
          title={updatedEntries.fields.title}
          description={updatedEntries.fields.description}
          noIndex={!updatedEntries.fields.seoIndex}
        />
        {props.preview && <ButtonPreview />}
        {assemblyFactory.createPageElements(updatedEntries.fields.elements)}
      </div>
    </ContentfulLivePreviewProvider>
  );
};

/**
 * Setup Contentful Layout
 *
 * @param {ReactElement} page - Page Content
 * @returns {ReactNode} Page Results
 */
CustomPage.getLayout = (page: ReactElement<CustomPageProps>): ReactNode => {
  switch (page.props.children.props?.fields?.layoutType) {
    case 'Minimal layout':
      return <MinimalLayout>{page.props.children}</MinimalLayout>;
    case 'No footer layout':
      return <NoFooterLayout>{page}</NoFooterLayout>;
    case 'Blank layout':
      return <BlankLayout>{page}</BlankLayout>;
    case 'Primary layout':
      return <PrimaryLayout>{page}</PrimaryLayout>;
    default:
      return <MinimalLayout>{page}</MinimalLayout>;
  }
};

export default CustomPage;

/**
 * @param {object} context - The context object
 * @returns {object} - The props object
 */
export const getServerSideProps = async (
  context: GetServerSidePropsContext<{
    /** The locale */
    locale?: string;
    /** The slug */
    slug: string;
  }>
) => {
  const isPreviewEnabled = context?.previewData ? true : false;

  const data = await getClient(
    isPreviewEnabled
  ).withoutUnresolvableLinks.getEntries<Custom['LostCustomPage']>({
    content_type: 'lostCustomPage',
    'fields.slugs': context.params?.slug || '/',
    locale: getContentfulLocale(context.locale),
    include: 10,
  });

  /**
   * Fixing circular reference error: The data structure of the article contains
   * references to the `acrossArticleCards` that in turn contains references to
   * the `acrossArticleCards` and so on. This was creating a circular reference
   * between the items, which can lead to unexpected behavior and memory leaks.
   * To fix the issue, we are assigning `null` to the `acrossArticleCards`
   * property of each item in the array, if the array exists. This breaks the
   * circular reference and prevents any potential issues.
   */
  try {
    if (data.items?.length > 0 && data.items[0]?.fields?.elements) {
      data.items[0].fields.elements.forEach((element) => {
        if (element?.sys.contentType.sys.id === 'relatedArticles') {
          const fields = (
            element as InferContentfulResponse<Topics['RelatedArticles']>
          )?.fields;

          const relatedArticles = fields?.articles
            ? fields.articles.map((article) => {
                if (article) {
                  article.fields.acrossArticleCards = [];
                }
                return article;
              })
            : [];
          element.fields = {
            articles: relatedArticles,
          };
        }
      });
    }
  } catch (error) {
    console.error(error);

    return {
      notFound: true,
    };
  }

  if (!data.items.length) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      ...data.items[0],
      preview: isPreviewEnabled,
    },
  };
};
