import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import Image, { ImageLoader } from 'next/legacy/image';
import Link from 'next/link';

/**
 * Aspect Ratios
 *
 * @type {aspectRatio} Aspect Ratios available for the Card Image
 */
export type aspectRatio = '4:3' | '1:1';

/**
 * ICardImage Interface for the Card Image Component
 *
 * @interface ICardImage
 */
export interface ICardImage {
  /**
   * The text to display.
   *
   * @memberof ICardImage
   * @member {string} text
   */
  text: string;
  /**
   * The image to display.
   *
   * @memberof ICardImage
   * @member {string} image
   */
  image: string;
  /**
   * The alt text for the image.
   *
   * @memberof ICardImage
   * @member {string} alt
   */
  alt: string;
  /**
   * The href to link to.
   *
   * @memberof ICardImage
   * @member {string} href
   */
  href: string;
  /**
   * The opacity of the image.
   *
   * @memberof ICardImage
   * @default 0
   * @member {number} [opacity]
   */
  opacity?: number;
  /**
   * The aspect ratio of the image.
   *
   * @memberof ICardImage
   * @member {aspectRatio} aspectRatio
   */
  aspectRatio: aspectRatio;
  /**
   * The optional classes to apply to the component
   *
   * @memberof ICardImage
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * The loader function for the image
   *
   * @memberof ICardImage
   * @member {ImageLoader} [loader]
   */
  loader?: ImageLoader;
}

/**
 * Card Image The Image Card component is used to display an image with text
 *
 * @param {ICardImage} props - The props for the Card Image Component
 * @returns {React.FC<ICardImage>} Card Image Component
 */
const CardImage: React.FC<ICardImage> = ({
  text,
  image,
  href,
  alt,
  opacity = 0.7,
  aspectRatio,
  classes = '',
  loader,
}: ICardImage) => {
  let aspectClass: string;
  switch (aspectRatio) {
    case '4:3':
      aspectClass = 'aspect-[4/3]';
      break;
    case '1:1':
      aspectClass = 'aspect-square';
      break;
  }
  return (
    <div
      data-testid="card-image"
      className={`group max-w-2xl relative ${classes} ${aspectClass}`}
    >
      <Link
        href={href}
        className="focus-visible:ring bg-black  focus-visible:ring-focus-400 w-full h-full relative flex rounded-[20px] sm:rounded-3xl overflow-hidden"
      >
        <Image
          src={image}
          alt={alt}
          style={{ opacity: opacity }}
          layout="fill"
          objectFit="cover"
          className="scale-100 group-hover:scale-[102%] transition-transform duration-100"
          loader={loader}
        />
        <Paragraph className="absolute text-neutral-100 text-h4 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          {text}
        </Paragraph>
      </Link>
    </div>
  );
};

export default CardImage;
