import Button, { IButton } from '@/components/atoms/Button/Button';
import ContentfulImage from '@/components/atoms/ContentfulImage/ContentfulImage';
import Heading from '@/components/atoms/Heading/Heading';
import clsx from 'clsx';

/**
 * ISideBySide
 *
 * @interface ISideBySide
 */
export interface ISideBySide {
  /**
   * The headline text to display.
   *
   * @memberof ISideBySide
   * @member {React.ReactNode | string} [title]
   */
  title?: React.ReactNode | string;
  /**
   * The content text to display.
   *
   * @memberof ISideBySide
   * @member {React.ReactNode | string} [body]
   */
  body?: React.ReactNode | string;
  /**
   * The button to display.
   *
   * @memberof ISideBySide
   * @member {IButton} [button1]
   */
  button1?: IButton;
  /**
   * The button to display.
   *
   * @memberof ISideBySide
   * @member {IButton} [button2]
   */
  button2?: IButton;
  /**
   * The image
   *
   * @memberof ISideBySide
   * @member {object} image
   */
  image: {
    /**
     * The image url
     *
     * @memberof ISideBySide.image
     * @member {string} url
     */
    url: string;
    /**
     * The image alt text
     *
     * @memberof ISideBySide.image
     * @member {string} [alt]
     */
    alt?: string;
    /**
     * The image width
     *
     * @memberof ISideBySide.image
     * @member {number} width
     */
    width: number;
    /**
     * The image height
     *
     * @memberof ISideBySide.image
     * @member {number} height
     */
    height: number;
  };
  /**
   * The image position
   *
   * @memberof ISideBySide
   * @member {boolean} imagePosition
   */
  imagePosition: boolean;
  /**
   * The optional classes
   *
   * @memberof ISideBySide
   * @default ''
   * @member {string} [className]
   */
  className?: string;
}

/**
 * SideBySide Illustration and content side by side
 *
 * @param {ISideBySide} props - The props for the SideBySide component
 * @returns {React.FC<ISideBySide>} Component
 */
const SideBySide: React.FC<ISideBySide> = ({
  title,
  body,
  button1,
  button2,
  image,
  imagePosition,
  className = '',
}: ISideBySide) => {
  return (
    <div data-testid="side-by-side" className={`py-12 sm:py-20 ${className}`}>
      <div className="grid grid-cols-4 sm:grid-cols-6 md:grid-cols-12 gap-4 sm:gap-6 md:gap-8 sm:items-center">
        {image.url && (
          <div
            className={clsx(
              'col-span-2 sm:col-span-3 md:col-span-3  mb-6 sm:mb-0',
              imagePosition ? 'sm:order-1 md:col-start-3' : 'sm:order-2'
            )}
          >
            <ContentfulImage
              src={image.url}
              alt={image.alt}
              width={image.width}
              height={image.height}
            />
          </div>
        )}

        <div
          className={clsx(
            'col-span-4 sm:col-span-3 md:col-span-5',
            imagePosition ? 'sm:order-2' : 'sm:order-1 md:col-start-3'
          )}
        >
          {title && (
            <Heading size="h2" font="petco" className="text-mkNavy-400 mb-6">
              {title}
            </Heading>
          )}

          {body && <div className="mb-8">{body}</div>}

          <div className="flex flex-col gap-4 sm:flex-row sm:gap-6">
            {button1 && <Button {...button1} />}
            {button2 && <Button {...button2} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SideBySide;
