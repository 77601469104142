import LogoGrid from '@/components/organisms/LogoGrid/LogoGrid';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '..';

/** LogoGridContainerProps */
type LogoGridContainerProps = ContainerProps['logoGrid'];

/**
 * @param {LogoGridContainerProps} props - LogoGridContainerProps
 * @returns {React.ReactNode} - LogoGridContainer
 */
export const LogoGridContainer = (props: LogoGridContainerProps) => {
  const logos = props.fields.logos
    .map((logo) => ({
      url: addHttps(logo?.fields?.file?.url),
      alt: logo?.fields.description || logo?.fields.title,
    }))
    .filter((logo) => logo.url);

  return (
    <div>
      <LogoGrid heading={props.fields.heading} logos={logos} />
    </div>
  );
};
