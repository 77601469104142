import TeamCard from '@/components/molecules/TeamCard/TeamCard';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { ContainerProps } from '@/lib/dataSource/contentful/containers';

/**
 * @param {ContainerProps['teamCard']} props - TeamCardContainer props
 * @returns {React.ReactNode} - TeamCardContainer component
 */
export const TeamCardContainer = (props: ContainerProps['teamCard']) => (
  <TeamCard
    name={props.fields.name}
    title={props.fields.title}
    image={addHttps(props.fields.image?.fields.file?.url)}
    url={props.fields.actionLink?.fields.url}
    label={props.fields.actionLink?.fields.label}
    target={props.fields.actionLink?.fields.target}
    alt={props.fields.altTitle}
  />
);
