import Hero from '@/components/organisms/Hero/Hero';
import { addHttps } from '@/lib/utils/stringReplace/addHttps';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContainerProps } from '..';

/**
 * @param {object} props - HeroContainerProps
 * @returns {React.ReactNode} - HeroContainer
 */
export const HeroContainer = (
  props: ContainerProps['hero'] & {
    /** Classname */
    className?: string;
    /** Whether to use a variant for the hero */
    useVariant?: boolean;
    /** Wave border color */
    waveBorderColor?: 'base 200' | 'base 300' | 'light green 400' | 'pink 400';
    /** The children to display. */
    children?: React.ReactNode;
  }
) => {
  return (
    <Hero
      className={props.className}
      headline={props.fields.headline}
      richHeadline={documentToReactComponents(props.fields.richHeadline, {
        renderMark: {
          // eslint-disable-next-line jsdoc/require-jsdoc
          bold: (text) => (
            <span className="font-bold text-mkNavy-400">{text}</span>
          ),
          // eslint-disable-next-line jsdoc/require-jsdoc
          italic: (text) => (
            <span className="font-light font-amasis italic text-mkNavy-400">
              {text}
            </span>
          ),
        },
      })}
      copy={props.fields.copy}
      bgImage={{
        url: addHttps(props.fields.backgroundImage?.fields.file?.url),
        altTitle: props.fields.backgroundImage?.fields.title,
      }}
      color={props.fields.color}
      waveBorderColor={props.waveBorderColor}
      useVariant={!!props.useVariant}
      button1={props.fields.actionLink1?.fields}
      button2={props.fields.actionLink2?.fields}
    >
      {props.children}
    </Hero>
  );
};
