import AccordionFaq from '@/components/organisms/AccordionFaq/AccordionFaq';
import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import { anchorTarget } from '@/lib/utils/anchorTarget';
import {
  documentToReactComponents,
  RenderNode,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import { twMerge } from 'tailwind-merge';
import { ContainerProps } from '..';

const renderOptions = {
  renderNode: {
    /**
     * Embedded Asset
     *
     * @param {Document} node - The node
     * @param {string} children - The children
     * @returns {React.ReactElement} - Image Component
     */
    [BLOCKS.PARAGRAPH]: (node: Document, children: string) => (
      <p className="font-normal mb-2">{children}</p>
    ),
    /**
     * List ul
     *
     * @param {Document} node - The node
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.UL_LIST]: (node: Document, children: string) => (
      <ul className="mb-4">{children}</ul>
    ),
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.LIST_ITEM]: (_, children) => (
      <li className="list-disc ml-5 [&>p]:m-0">{children}</li>
    ),
    /**
     * @param {object} node - Node Data
     * @param {object} node.data - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode | void} - ReactNode
     */
    [INLINES.HYPERLINK]: ({ data }, children) => {
      return anchorTarget(data.uri, children);
    },
  } as RenderNode,
};

/**
 * @param {object} props - AccordionFaqContainerProps
 * @returns {React.ReactNode} - AccordionFaqContainer
 */
export const AccordionFaqContainer = (
  props: ContainerProps['faq'] & {
    /** Classname */
    className?: string;
  }
) => {
  return (
    <AnimateItem from={{ translateY: 20 }}>
      <div className="px-6 sm:px-8 md:px-10 md:grid md:grid-cols-12 md:gap-6">
        <AccordionFaq
          title={props.fields.headline}
          subTitle={props.fields.copy}
          content={
            props.fields?.rows && props.fields.rows.length > 0
              ? props.fields.rows.map((item) => ({
                  header: item?.fields.headline || '',
                  content: item?.fields.copyRichText
                    ? documentToReactComponents(
                        item.fields.copyRichText,
                        renderOptions
                      )
                    : item?.fields.copy || '',
                }))
              : []
          }
          classes={twMerge('md:col-span-6 md:col-start-4', props.className)}
        />
      </div>
    </AnimateItem>
  );
};
