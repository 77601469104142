import { ContainerProps } from '@/lib/dataSource/contentful/containers';
import { Widget } from '@typeform/embed-react';

/**
 * @param {ContainerProps['typeForm']} props - TypeFormContainer props
 * @returns {React.ReactNode} - TypeFormContainer
 */
export const TypeFormContainer = (props: ContainerProps['typeForm']) => {
  return (
    <div data-testid="typeform-container">
      <Widget
        id={props.fields.formId}
        className="md:h-screen"
        autoResize={true}
        inlineOnMobile={true}
      />
    </div>
  );
};
