import CdnImage from '@/components/atoms/CdnImage/CdnImage';
import Heading from '@/components/atoms/Heading/Heading';
import Paragraph from '@/components/atoms/Paragraph/Paragraph';
import { anchorTarget } from '@/lib/utils/anchorTarget';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types';
import React from 'react';

const options: Options = {
  renderNode: {
    /**
     * @param {object} _ - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode} - ReactNode
     */
    [BLOCKS.PARAGRAPH]: (_, children) => (
      <Paragraph size="body4" className="[&>a:hover]:text-base-300">
        {children}
      </Paragraph>
    ),

    /**
     * @param {object} node - Node Data
     * @param {object} node.data - Node Data
     * @param {React.ReactNode} children - Node Children Tree
     * @returns {React.ReactNode | void} - ReactNode
     */
    [INLINES.HYPERLINK]: ({ data }, children) => {
      // Only process youtube links
      if (data.uri) {
        return anchorTarget(data.uri, children);
      }
    },
  },
};

/**
 * IInfoGraphicCard
 *
 * @interface IInfoGraphicCard
 */
export interface IInfoGraphicCard {
  /**
   * The image to display.
   *
   * @memberof IInfoGraphicCard
   * @member {string} image
   */
  image: string;
  /**
   * The title to display.
   *
   * @memberof IInfoGraphicCard
   * @member {string} title
   */
  title: string;
  /**
   * The description to display.
   *
   * @memberof IInfoGraphicCard
   * @member {string | React.ReactNode} [description]
   */
  description?: string | React.ReactNode;
  /**
   * The description to display RichText support.
   *
   * @memberof IInfoGraphicCard
   * @member {Document} [descriptionRichText]
   */
  descriptionRichText?: Document;
  /**
   * The optional classes to apply to the component.
   *
   * @memberof IInfoGraphicCard
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * Whether to use the CdnImage component or no loader.
   *
   * @memberof IInfoGraphicCard
   * @default false
   * @member {boolean} [noLoader]
   */
  noLoader?: boolean;
}

/**
 * InfoGraphicCard Displays an image, title and description. Description is
 * passed in as either a string or a React Node so things like text with links
 * can be passed in
 *
 * @param {IInfoGraphicCard} props - The props for the InfoGraphicCard component
 * @returns {React.FC<IInfoGraphicCard>} InfoGraphicCard Component
 */
const InfoGraphicCard: React.FC<IInfoGraphicCard> = ({
  image,
  title,
  description,
  descriptionRichText,
  classes = '',
  noLoader,
}: IInfoGraphicCard) => {
  return (
    <div
      data-testid="info-graphic-card"
      className={`w-full px-6 mb:px-8 text-center info-graphic-card ${classes}`}
    >
      <div className="relative h-[114px] mb-6 md:mb-8">
        {noLoader ? (
          // eslint-disable-next-line @next/next/no-img-element
          <img
            src={image}
            className="absolute top-0 bottom-0 right-0 left-0 m-auto block object-scale-down"
            loading="lazy"
            alt={`${title} image`}
          />
        ) : (
          <CdnImage
            src={image}
            layout="fill"
            objectFit="contain"
            loading="lazy"
            alt={`${title} image`}
            sizes="200px"
          />
        )}
      </div>
      <Heading size="h5" className="mb-4 text-secondaryBase-400">
        {title}
      </Heading>

      {descriptionRichText &&
        documentToReactComponents(descriptionRichText, options)}

      {!descriptionRichText && description && (
        <Paragraph size="body4" className="[&>a:hover]:text-base-300">
          {description}
        </Paragraph>
      )}
    </div>
  );
};

export default InfoGraphicCard;
