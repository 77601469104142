import { IBlobListItem } from './BlobList';

/**
 * @param {IBlobListItem} props - The props for the Blob component
 * @returns {React.FC<IBlobListItem>} Blob Component
 */
const BlobFour: React.FC<IBlobListItem> = ({
  size,
  color,
  id = 'blob-four',
}: IBlobListItem) => {
  return (
    <svg
      height={size}
      id={id}
      data-testid="svg-blob-four"
      viewBox="0 0 316 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91 72C91 43.2812 122.221 6.99999 159.5 6.99999C196.779 7 226 43.2812 226 72C226 100.719 201.779 107.5 164.5 107.5C127.221 107.5 91 100.719 91 72Z"
        fill={color}
      />
    </svg>
  );
};

export default BlobFour;
